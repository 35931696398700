<template>
    <b-modal size="xl" id="kpiDataModal" hide-footer style="height:100%;">
        <template #modal-header>
            <div class="d-flex">
                <span v-if="payload.item_type == 'kpi'" class="badge-item badge-item-key-performance-indicator"></span> 
                <span v-if="payload.item_type == 'target'"  class="badge-item badge-item-target"></span> 
                <span>{{ payload.graph_name }}</span>
                <span class="px-2">-</span>
                <span>{{ payload.item_name }}</span>
            </div> 
        </template>
        <div>
            
        <graphDataSheet
            v-if="has_mounted"
            :item="payload"
            @nextDate="$emit('nextDate')"
            @prevDate="$emit('prevDate')"
            @handleSave="handleSave"
            @handleSoftSave="handleSoftSave"
            @reloadGraphData="handleGraphDataRefresh"/>
        <!-- <b-table-simple v-if="showOldInput" class="kpiDataTable mt-1" small bordered sticky-header style="overflow-x: hidden;" >
            <b-thead>
                <b-tr>
                    <b-th style="background-color: #d6d8db !important; width:40%">Date Range ({{ payload.tracking_type }})</b-th>
                    <b-th class="text-end" style="background-color: #d6d8db !important; width:60%">Values</b-th>
                </b-tr>
            </b-thead>
            <b-tbody >
                <b-tr v-for="(item,idx) in data" :key="idx" style="">
                    <b-td class="text-secondary" style="display: flex; justify-content: center;">
                            {{ Object.keys(item)[0] }}
                    </b-td>
                    <b-td class="valueStyles" style="width:60%;">
                        <b-form-input class="inputStyles text-end cursor-pointer" type="number" v-model.number="data[idx][Object.keys(item)[0]]" :value="Number(Object.values(item)[0])"></b-form-input>
                    </b-td>  
                </b-tr>
            </b-tbody>
        </b-table-simple> -->
        </div>
        <div style="display: flex; justify-content: end;">
            <!-- <b-button v-if="showOldInput" class="me-2" variant="primary" @click="saveTable" size="sm">Save Changes</b-button> -->
            <b-button variant="info" @click="closeModal" size="sm">Back</b-button>
        </div>
        <b-overlay no-wrap :show="is_saving"></b-overlay>
    </b-modal>
</template>

<script>
//import Swal from 'sweetalert2';
import { levelComputed, activityComputed } from '@/state/helpers'
import graphDataSheet from '@/components/objectives/performance2/graph/graphDataSheet.vue';


//import _ from 'lodash'
export default {
    components:{
        graphDataSheet,
    },
props:{
    payload:{
        required: true,
    },
    parent_tracking_period:{
        required: true,
    }
},
data:() => ({
    show_me: true,
    is_loading: false,
    activity_plans_data: [],
    objective_data: [],
    data: [],
    changesMade: false,
    is_saving: false,

    has_mounted: false,
}),
computed:{
    ...levelComputed,
    ...activityComputed,
    canShowNewTable(){
        return this.tracking_type == 'daily' || this.tracking_type == 'weekly' || this.tracking_type == 'monthly';
    },
    showOldInput(){
        return this.parent_tracking_period != this.payload.tracking_type;
    },

},
methods:{
    closeModal(){
        this.is_saving = true;
        setTimeout(()=>{
            //window.webix.$$('datasetTable').editStop();
            this.$bvModal.hide('kpiDataModal')
        }, 1000)
        
    },  
    saveTable() {
        let params = {
            "item_id": this.payload.item_id,
            "item_type": this.payload.item_type,
            "tracking_values" : this.data
        }
        this.$emit('saveData', params);
        this.is_saving = true;
    },
    handleSave(save_data){
        let params = {
            "item_id": this.payload.item_id,
            "item_type": this.payload.item_type,
            "tracking_values" : [save_data]
        }
        this.$emit('saveData', params);
        this.is_saving = true;
    },
    handleSoftSave(save_data){
        let params = {
            "no_reload": true,
            "item_id": this.payload.item_id,
            "item_type": this.payload.item_type,
            "tracking_values" : [save_data]
        }
        this.$emit('saveData', params);
       // this.is_saving = true;
    },
    handleGraphDataRefresh(){
        this.$emit('refreshData');
    }
},

mounted(){
    this.data = this.payload.tracking_values;
    this.$nextTick(()=>{
        this.has_mounted = true;
    })
},
}
</script>
<style>
.webixModal .webix_selected{
    /*background-color: #daecff !important;*/
    text-decoration: underline black ;
    color:dodgerblue;
    font-weight: bold;
}
.show_objective_modal{
    cursor: pointer;
    color: transparent;
}
.inputStyles {
    height: 28px; 
    border: 0;
    padding-top: 10px !important;
    margin: 0px !important; 
    padding: 0 auto !important;
    float: right
}
.valueStyles {
    height: 28px;
    padding: 0px !important;
    border: 3px solid #f8f9fa !important;
}
.form-control:focus {
  margin: 0px;
  border: 1px solid;
  border-radius: 0%;
  border-color: #1ca1c1 ;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 3px #1ca1c1 ;
}

.kpiDataTable {
  border: 1px solid;
  border-radius: 0%;
  border-color: #f8f9fa !important ;
  box-shadow: inset 0 1px 1px #f8f9fa, 0 0 3px #f8f9fa !important ;
}

.form-control:hover {
  margin: 0px;
  background-color: #f8f9fa;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-03T18:57:08+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-04T16:15:27+02:00
@Copyright: Diversitude 2021
-->


<!-- //* Display key results graph and modal -->
<template>
    
    <div> <!-- :style="maxHeight" -->
        <b-alert variant="warning" :show="hasUnitTypes">
            <div>
                No unity types found, please add unit types from the right hand menu before adding Targets
            </div>
            <b-button class="mt-2" variant="primary" size="sm" @click="showUnitTypeModal()">
                <font-awesome-icon icon="fa-plus"></font-awesome-icon>
                Unit Types
            </b-button>
        </b-alert>
        



        <Table
            v-show="show_table == true"
            :active_id="selectedID"
            :uid="table_uid"
            :ref="table_uid"
            :enable_edit="true"
            :is_loading="is_loading"
            :data="key_results"
            :default_values="default_values"
            :columns="columns"
            :simple_columns="simple_columns"
            :table_mode="table_view_mode"
            :context_menu_items="['View', 'Edit', 'Delete']"
            :only_editable_columns="true"
            :validation="table_validation_rules"
            :compact_mode="show_kpi_component"
            :ignore_edit_only_rules="['id','linked_objective_id', 'level_id']"
            :button_methods="button_methods"
            re_order
            has_header_filters
            prevent_first_row_select
            :hide_action_buttons="show_kpi_component"
            :disable_action_buttons="areMultipleObjectivesSelected"
            :variable_watchers="variable_watchers"
            :allow_duplication_feature="true"
            :child_uid="'targetsTable'"
            :min_height="166"
            guideline_key="targets"
            @handleDuplicateionItemsInsert="handleDuplicateionItemsInsert"
            @updatedOrderEvent="onRowIndexUpdate"
            @updateViewMode="updateViewMode"
            @selectedRowEvent="selectedRowEvent2"
            @onDataUpdate="onChangesMade"
            @onDeleteArrayUpdate="onDeleteArrayUpdate"
            enforce_structure_checks
            @saveTable="saveTable"
            @deleteRows="deleteRow"
        >
            <template #title>
                {{ titleName }}
                &nbsp;&nbsp;>&nbsp;&nbsp;
                <span class="text-primary"> {{ getSelectedLevelName }}</span>
            </template>
            <template #buttons>

                <b-button :disabled="selected_target == -1" v-if="is_company_level == false" :variant="show_kpi_component ? 'warning' : 'primary'" @click="handleShowKpis" size="sm" :class="show_table ? 'me-2' : ''" >
                    <span v-show="show_kpi_component">Hide Kpis</span>
                    <span v-show="!show_kpi_component">Show Kpis {{ getKpiCount }}</span>
                </b-button>

                <span class="btn-group me-2">
                    <b-button @click="toggleTableView()" size="sm" :variant="!show_table ? 'primary' : 'outline-primary'"  v-b-tooltip.hover.bottomright title="Card View" >
                        <font-awesome-icon icon="fa-chart-area"></font-awesome-icon>
                    </b-button>
                    <b-button @click="toggleTableView()" size="sm" :variant="show_table ? 'primary' : 'outline-primary'"  v-b-tooltip.hover.bottomright title="List">
                        <font-awesome-icon icon=" fa-list-alt"></font-awesome-icon>
                    </b-button>
                </span>
            </template>
            
            <template #extra-items>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="showUnitTypeModal">
                    <i class="fas fa-wrench me-1"></i>Manage Unit Types
                </b-dropdown-item>
            </template>
        </Table>
        <CardHeader class="pb-1" v-if="show_table == false">
            <template #title>
                <div class="ps-3 pt-1 ">
                    {{ titleName }}
                    &nbsp;&nbsp;>&nbsp;&nbsp;
                    <span class="text-primary">{{ getSelectedLevelName }}</span>
                </div>
            </template>
            <template #buttons>
                <span class="btn-group me-4">
                    <b-button @click="toggleTableView()" size="sm" :variant="!show_table ? 'primary' : 'outline-primary'"  v-b-tooltip.hover.bottomright title="Card View">
                        <font-awesome-icon icon="fa-chart-area"></font-awesome-icon>
                    </b-button>
                    <b-button @click="toggleTableView()" size="sm" :variant="show_table ? 'primary' : 'outline-primary'"  v-b-tooltip.hover.bottomright title="List">
                        <font-awesome-icon icon=" fa-list-alt"></font-awesome-icon>
                    </b-button>
                </span>

                <!-- //! Perf 2.0 -->
                <!-- <b-button :disabled="selected_target == -1" v-if="is_company_level == false" :variant="show_kpi_component ? 'warning' : 'primary'" @click="handleShowKpis" size="sm" >
                    <span v-show="show_kpi_component">Hide Kpis</span>
                    <span v-show="!show_kpi_component">Show Kpis ({{getKpiCount}})</span>
                </b-button> -->
            </template>
            <template #extra-items>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="showUnitTypeModal">
                    <i class="fas fa-wrench me-1"></i>Manage Unit Types
                </b-dropdown-item>
            </template>
            
        </CardHeader>
        <div class="d-flex flex-wrap" style="width: 100%;" >
            </div>
            <!-- Content -->
            <div>
                <div>
                    <!-- flip front -->
                    <div v-show="flip" style="text-align: left;">
                        <GraphNav
                            v-if="show_table == false && hasKeyResults && can_render_graph"
                            @next="graphNext"
                            @previous="graphPrevious"
                            :disable_buttons="is_editing_kr_dataset"
                            :hide_graph="selectedID != -1"
                            :is_collapsed="selectedID == -1"
                            >
                            <template #cards >
                                <div class="pretty-tiles">
                                        <div  class="outerContainer" style="" >
                                            <transition-group name="fade" class="newTileContainer">
                                                <TileCard
                                                    v-for="item in key_results" :key="item.id"
                                                    :id="item.id"
                                                    :item="item"
                                                    :active_id="selectedID"
                                                    type="target"
                                                    @selectedItem="selectedCardEvent"
                                                >
                                                </TileCard> 
                                            </transition-group>
                                        </div>
                                </div>
                            </template>
                            <template #graph >
                                <div v-if="get_selected_target && isCompleteTarget == false">
                                    <GraphMissingDataForm :item="get_selected_target"/>
                                </div>
                                    <LineGraph
                                        v-if="selected_target != -1 && graph_index > -1 && show && get_selected_target.target != null"
                                        :are_kpis_visible="show_kpi_component"
                                        @graphID="setVisibleGraphID"
                                        @toggleEdit="handleEditState"
                                        @handleShowKpis="handleShowKpis"
                                        :data="get_selected_target"
                                        :is_key_result="true" 
                                        :middle_text="graphIndexText"/>
                            </template>
                        </GraphNav>

                        <div v-show="show_table == false && hasKeyResults == false">
                            <b-alert show class="mb-0">
                                No Targets Added
                            </b-alert>
                        </div>
                        
                    </div>
                    <!-- flip back -->
                    <div class="" v-if="!flip">
                        <!-- Guideline -->
                        <Guideline
                        v-if="isShow.isGuideline || isEditGuideline"
                        ref="guideline"
                        :cardName="'KeyResult'"
                        :isEditGuideline="isEditGuideline"
                        @refresh="update('guideline')"
                        />
                    </div>
                </div>
            </div>
            <ObjectiveModal
            v-if="show_objective_modal"
            :payload="modal_payload"
            not_objective
            @selectedNewID="handleObjectiveChangedEvent"
        />
        <UnitTypeModal v-if="show_units_modal"/>
        <HierarchyModal
            v-if="show_hierarchy_modal"
            :company_level="isParentLevel"
            :payload="modal_payload"
            @selectedNewID="handleLevelUpdateEvent"
            @updatedNewID="handleLevelUpdateEvent"
        />
    </div>
</template>

<script>
 
import GraphNav from '@/components/widgets/graphNavigationContainer.vue'

import _ from "lodash";
// eslint-disable-next-line
import ApexCharts from 'apexcharts';
// import * as webix from "../../../webix-pro/webix";
// import ObjectiveDropdown from "@/components/objectives/components/objective-dropdown.vue";
import Guideline from "@/components/objectives/components/guideline.vue";
//eslint-disable-next-line
import { format, parseISO } from "date-fns";
//eslint-disable-next-line
import { isAfter, isBefore, isEqual, endOfWeek, endOfMonth, endOfQuarter, endOfYear} from 'date-fns'
import {
    levelMethods,
    levelComputed,
    activityMethods,
    activityComputed,
    adminComputed,
    cardHeightComputed,
    cardHeightMethods,
    webixTableState,
    uxToggleComputed,
    uxToggleMethods,

    performanceMethods,
    performanceComputed,
} from "@/state/helpers";
import Table from '@/components/widgets/table/table.vue'
import Swal from 'sweetalert2';
import LineGraph from '../../../widgets/objectives/targetGraph.vue'
import CardHeader from '@/components/widgets/cardHeader.vue'

import ObjectiveModal from '../misc/objectiveModals/linkedObjectiveModal.vue'
import HierarchyModal from '../misc/hierarchyModal.vue'

import UnitTypeModal from '../misc/unitTypesModal.vue'
import dateMixin from '@/mixins/dateMixin.js'
import tableMixin from '@/mixins/tableHelpers.js';
import TileCard from '@/components/objectives/components/tiles/tileCardModernGraph.vue'
import GraphMissingDataForm from '../graph-components/GraphMissingDataForm.vue'
export default {
    mixins:[ dateMixin, tableMixin ],
    components: {
        Guideline,
        // ObjectiveDropdown,
        Table,
        LineGraph,
        CardHeader,
        GraphNav,
        ObjectiveModal,
        UnitTypeModal,
        TileCard,
        GraphMissingDataForm,
        HierarchyModal
    },
    props:{
        is_parent_level:{
            default: false,
        },
    },
    data: () => ({
        show_hierarchy_modal: false,
        visible_graph_id: null,
        //* bool to expand b-collapse with graph inside
        show_table: false,
        show: true,
        is_editing: false, //* is graph in edit mode
        //* value emitted to show kpis in parent component columns 
        //show_kpis: false,
        //* table vars
        changes_made_flag: false,
        table_uid: 'targetsTable',
        table_view_mode: 'view', //* (view - edit - delete)
        delete_array: [], //* array of items flagged to be deleted from table component
        is_loading: false,
        //* ----------

        filterText: "",
        filter: {
            dateStart: "",
            dateEnd: "",
        },

        show_units_modal: false,
        show_objective_modal: false,
        modal_payload: null,

        isFormError: false,
        isEditGuideline: false,
        isShow: {
        isCancel: false,
        isView: true,
        isEditingExisting: false,
        isEditingNew: false,
        isComment: false,
        isGuideline: false,
        },
        flip: true,
        /*form input data*/

        can_render_graph:true,

    }),
    watch: {
        selected_goal_objective(new_val){
            //alert(new_val)
            // clears current_selected_objective vuex state
            if(new_val == -1){
                this.setCurrentObjectiveDetails(null);
            }
        },
        hide_action_buttons(new_val){
            console.log('hide_action_buttons', new_val)
        },
        //eslint-disable-next-line
        selected_objective(newVal, oldVal){
            if(!newVal || !oldVal){
                this.is_editing = false;
                return;
            }
            else if(newVal.id != oldVal.id){
                this.is_editing = false;
                // hide dataset edit table when selection changes
                this.setGraphEditState({
                    type: 'kr',
                    state: false,
                });
                this.setGraphEditState({
                    type: 'kpi',
                    state: false,
                });
                this.setGraphEditState({
                    type: 'metric',
                    state: false,
                });
            }
        },
        key_results(items){
            if(items.length == 0){
                this.setGraphToggleState({
                    type: 'kpi',
                    state: false,
                })
            }
        },
        selected_key_result(new_key_result){
            if(new_key_result == null){
                this.setGraphToggleState({
                    type: 'kpi',
                    state: false,
                });
            }
        },
        show_kpi_toggle(val){
            if(val){
                this.can_render_graph = false;
                this.$nextTick(()=>{
                    this.can_render_graph = true
                })
            }
        },
        show_kpi_component(){
            this.$nextTick(()=>{
                if(window.webix.$$(this.table_uid) != undefined){
                    //* fix webix table size 
                    window.webix.$$(this.table_uid).refresh();
                }
                if(this.show_kpi_component == true && this.show_table == false && this.selectedID > -1){
                    //*fix graph not resizing
                    setTimeout(()=>{
                        ApexCharts.exec(this.visible_graph_id, 'resetSeries', true);
                        ApexCharts.exec(`brushChart${this.visible_graph_id}`, 'resetSeries', true);
                    }, 200);
                }
                //this.setGraphToggleState({
                //    type: 'kpi',
                //    state: this.show_kpis,
                //});
                
            })
        },
        mode(newValue){
            if(newValue != null){
                this.show_table = newValue;
            }
        },
        show_table(state){
            this.$emit('toggleTableMode', state);
            if(state == true){
                this.$nextTick(()=>{
                    window.webix.$$(this.table_uid).refresh();
                })
            }
        },
        canShowHeaderFilters(){
            this.$nextTick(()=>{
                //* check if ref is valid (should be)
                if(this.$refs[this.table_uid]){
                    //*call method from table component
                    this.$refs[this.table_uid].refreshVisibleColumns()
                }
            })
        },
        selected_target(new_value){
            if(new_value == -1){
                this.setShowKpis(false);
            }
            else{
                this.scrollToCard(new_value)
            }
        },

    },
    methods: {
        ...activityMethods,
        ...levelMethods,
        ...cardHeightMethods,
        ...uxToggleMethods,
        ...performanceMethods,
        handleLevelUpdateEvent(newID){
            let table = window.webix.$$(this.table_uid);
            // eslint-disable-next-line     
            let item = table.getItem(this.modal_payload.id);
            item['level_id'] = Number(newID);
            if(this.modal_payload.is_new){
                item['is_new'] = true;
            }
            //this.updateNewRowItem(table, item, this.modal_payload.id, newID)
            table.updateItem(item.id, item);
            this.$bvModal.hide('hierarchyModal')
        },
        handleDatasetBack(){
            let params2 = {
                level_id: this.current_level_id,
                //user_id: this.selected_user_id ? this.selected_user_id : 0,
                //objective_id: this.selected_goal_objective,
                //objective_data: 1,
                graph_data: 1
            }
            if(this.selected_user_id != -1){
                params2['user_id'] = this.selected_user_id;
            }

            this.loadPerformanceScreenData({
                type: 'targets',
                params: params2,
            })
        },
        scrollToCard(id = this.selected_item_id){
            if(id == -1){
                return;
            }
            let element = document.getElementById(id);
            if (element) {
                this.$nextTick(()=>{
                    element.scrollIntoView({ behavior: 'smooth', block: "nearest", inline: "nearest" });
                })
            }
        },
        showNotesModal(event_data){
            this.$emit('showNotesModal', event_data);
        },
        handleShowKpis(force_state = null){
            if(force_state != null){
                this.setShowKpis(force_state);
                return;
            }

            // normal path
            if(this.show_kpi_component){
                this.setShowKpis(false);
            }
            else{
                this.setShowKpis(true);
            }
        },
        async toggleTableView(){
            if(this.show_table){
                //* only run if table is visible 
                if(await this.$hasUnsavedChanges(this.table_uid)){
                    //* stop if unsaved changes are found
                    return false;
                }
                else{
                    this.$refs[this.table_uid].table_mode = 'view'; 
                }
            }
            this.show_table = !this.show_table;
        },
        setVisibleGraphID(id){
            this.visible_graph_id = id;
        },
        handleEditState(state){
            this.is_editing = state;
        },
        /* graph navigation methods*/
        graphNext(){
            if(this.graph_index < this.level_targets.length-1){
                let id = this.level_targets[this.graph_index+1].id;
                this.selectedRowEvent({id: id});
            }
            else{
                let id = this.level_targets[0].id;
                this.selectedRowEvent({id: id});
            }
        },
        graphPrevious(){
            if(this.graph_index > 0){
                let id = this.level_targets[this.graph_index-1].id;
                this.selectedRowEvent({id: id});
            }
            else{
                let id = this.level_targets[this.level_targets.length-1].id;
                this.selectedRowEvent({id: id});
            }

            
            
        },
        //* table methods
        toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('delete');
            }
            else{
                this.table_view_mode = 'delete';
            }
        },
        toggleViewMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('view')
            }
            else{
                this.$refs[this.table_uid].table_mode = 'view'; 
            }
            
        },
        showDiscardPrompt(type){
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.$refs[this.table_uid].table_mode = type; 
                }
            });
        },
        saveTable(data){
            //* if a row is new, need to fire off seperate endpoint to save share type
            //let update_share_types = [];
            let rows_to_save = [];
            data.forEach( row => {
                let params = {
                    //milestone_objective_id: row.milestone_objective_id ? row.milestone_objective_id : this.selected_objective.id,//row.milestone_objective_id,
                    id: row.is_new ? -1 : row.id,
                    level_id: row.level_id ? row.level_id : this.selected_level.id,
                    owner_user_id: row.owner_user_id,
                    name: row.name,
                    target: row.target,
                    unit_type_id: row.unit_type_id,
                    graph_type: row.graph_type,
                    min_value: row.min_value,
                    max_value: row.max_value,
                    current_time_period: row.current_time_period,
                    graph_size: '12', //only one graph displays at a time, so force full width graph
                    show_cumulative: Number(row.show_cumulative),
                    show_cumulative_target: Number(row.show_cumulative_target),
                    target_type: row.target_type,
                    include_weekends: row.include_weekends ? Number(row.include_weekends) : 0,
                    //notes: row.notes != null ? row.notes : '',
                }
                rows_to_save.push(params)
            })
            let target_params = {
                targets: rows_to_save
            }
            this.is_loading = true;
            this.saveTargets(target_params)
            .then(()=>{
                this.changes_made_flag = false;
                let params2 = {
                    level_id: this.current_level_id,
                    graph_data: 1
                }
                if(this.selected_user_id != -1){
                    params2['user_id'] = this.selected_user_id;
                }  
                
                this.loadPerformanceScreenData({
                    type: 'targets',
                    params: params2,
                })
                .then(()=>{
                    this.is_loading = false;
                    this.$refs[this.table_uid].table_mode = 'view';
                })
            })
            .catch(()=>{
                this.is_loading = false;
            })
        },
        handleTargetRemove(row){
            let delete_date = null;
            switch(row.current_time_period){
                case 'daily':{
                    delete_date = new Date(row.old_target_date);
                    break;
                }
                case 'weekly':{
                    delete_date = endOfWeek(new Date(row.old_target_date));
                    break;
                }
                case 'monthly':{
                    delete_date = endOfMonth(new Date(row.old_target_date));
                    break;
                }
                case 'yearly':{
                    delete_date = endOfYear(new Date(row.old_target_date));
                    break;
                }
            }
            delete_date = this.getSimpleDate(delete_date);
            //must delete old record here
            let index = _.findIndex(row.tracking, item => {
                console.log('must remove target',this.getSimpleDate(item.achieved_date),this.getSimpleDate(delete_date))
                return this.getSimpleDate(item.achieved_date) == this.getSimpleDate(delete_date);
            })
            if(index != -1){
                let params = {
                    key_result_id: row.id,
                    tracking_data: [{
                        achieved_date: this.getBackendDateFormat(delete_date),
                        value: row.tracking[index].value, //preserve value
                        //* save target val as 0 if it is null
                        target: null, // remove target
                    }],
                }
                this.saveKeyResultTracking(params)
                .catch( e => {
                    console.log('Error updating graphs target in dataset table', e);
                })
            }
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected target?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.deleteRow()
                }
            });
        },
        deleteRow(){
            let promises = [];
            this.delete_array.forEach( item => {
                promises.push(this.deleteTarget(item.id));
            })

            this.is_loading = true;
            Promise.all(promises)
            .then(()=>{
                this.changes_made_flag = false;
                this.delete_array = [];
                
                this.changes_made_flag = false;
                let params2 = {
                    level_id: this.current_level_id,
                    graph_data: 1
                }

                if(this.selected_user_id != -1){
                    params2['user_id'] = this.selected_user_id;
                }

                this.loadPerformanceScreenData({
                    type: 'targets',
                    params: params2,
                })
                .then(()=>{
                    this.is_loading = false;
                    this.$refs[this.table_uid].table_mode = 'view';
                })
            })
            .catch(()=>{
                this.is_loading = false;
            })
        },
        //* -------------

        //* table events start -----
        updateViewMode(mode){
            this.table_view_mode = mode;
        },
        selectedCardEvent(item){
            if(item.id != this.selectedID){
                this.setSelectedTarget(item.id);
            }
            else{
                this.setSelectedTarget(-1)
            }
        },
        selectedRowEvent2(row){
            this.selectedRowEvent(row);
        },
        selectedRowEvent: _.debounce(function(row){
            if(!row){
                this.setSelectedTarget(-1);
                //this.$eventHub.$emit("selectedKeyResult", null);
                return;
            }
            //* Event returns the selected row
            let index = _.findIndex(this.level_targets, { id: row.id });
            if (index != -1) {
                let level_target = this.level_targets[index];
                this.setSelectedTarget(level_target.id);
                //this.$eventHub.$emit("selectedKeyResult", key_result);
            }
            else{
                this.setSelectedTarget(-1);
            }

        },200),
        selectedRowEventOld(row){
            if(!row){
                this.setSelectedTarget(-1);
                //this.$eventHub.$emit("selectedKeyResult", null);
                return;
            }
            //* Event returns the selected row
            let index = _.findIndex(this.level_targets, { id: row.id });
            if (index != -1) {
                let level_target = this.level_targets[index];
                this.setSelectedTarget(level_target.id);
                //this.$eventHub.$emit("selectedKeyResult", key_result);
            }
            else{
                this.setSelectedTarget(-1);
            }
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        handleDuplicateionItemsInsert(payload){//table_uid and target_id
            let index = this.duplicate_clipboard.findIndex( clipboard => {
                return payload.table_uid == clipboard.uid;
            })
            if(index == -1){
                alert('Error')
                return;
            }
            else{
                this.is_loading = true;
                let data = {
                    destination_type: 'objective',
                    destination_id: this.selected_objective.id,
                    sources: this.duplicate_clipboard[index].rows,
                    source_type:'key_result',
                }
                this.sendDuplicationRequest(data)
                .then(()=>{
                    let params = {
                        id: this.selected_objective.id,
                        user_id: this.activeUserId,
                    }
                    this.loadObjectiveDetailAPI(params)
                    .then(()=>{
                        this.is_loading = false;
                        this.$refs[this.table_uid].table_mode = 'view';
                        this.$swal.fire({
                            icon:'success',
                            title:'Success',
                            toast: true,
                            position: 'top',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    })
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            }
        },
        //eslint-disable-next-line
        onRowIndexUpdate(sortData){
            this.is_loading = true;
            this.updateRowOrder({type:'kr', sortData:sortData})
            .then(()=>{
                let params = {
                    //id: this.selected_level.id,
                    id: this.selected_objective.id,
                    user_id: this.activeUserId,
                }
                this.loadObjectiveDetailAPI(params)
                .then(()=>{
                    this.is_loading = false;
                })
            })
        },
        //* table events end ------
        searchText(value) {
            let text = value.toString().toLowerCase();
            // eslint-disable-next-line
            $$(this.table_uid).filter(function(obj) {
                if(obj.name != undefined){
                    return (
                    obj.name
                        .toString()
                        .toLowerCase()
                        .indexOf(text) != -1
                    );
                }
            });
        },
        searchDate(value) {
            this.filter.dateStart = value.start_date;
            this.filter.dateEnd = value.end_date;
        },
        resetFilter() {
            this.filterText = "";
            this.filter.dateStart = "";
            this.filter.dateEnd = "";
        },
        update() {
            this.isEditGuideline = false;
            this.clearView();
            this.$refs.isEditingNew = false;
            this.$refs.isEditingExisting = false;
            this.isShow.isGuideline = true;
        },
        clearView() {
            this.isEditGuideline = false;
            Object.keys(this.isShow).forEach((item) => {
                this.isShow[item] = false;
            });
        },
        showUnitTypeModal(){
            this.show_units_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('unitTypesModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "unitTypesModal") {
                        this.show_units_modal = false;

                        //* refresh the array of avaliable unit types
                        window.webix.$$(this.table_uid).getColumnConfig('unit_type_id').collection.clearAll();
                        this.availableUnitTypes.forEach((item)=>{
                            window.webix.$$(this.table_uid).getColumnConfig('unit_type_id').collection.add(item);
                        })
                        window.webix.$$(this.table_uid).getColumnConfig('unit_type_id').collection.refresh();
                        window.webix.$$(this.table_uid).refreshColumns();
                        window.webix.$$(this.table_uid).getColumnConfig('unit_type_id').collection.refresh();
                        window.webix.$$(this.table_uid).refresh();
                    }
                });
            })
        },
        showHierarchyModal(payload){
            this.modal_payload = _.cloneDeep(payload);
            this.modal_payload['modal_type'] = 'Objective'
            this.show_hierarchy_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('hierarchyModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "hierarchyModal") {
                        this.show_hierarchy_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
        showObjectiveModal(payload){
            if(this.changes_made_flag){
                Swal.fire('Please save new rows before making hierarchy changes')
            }
            else{
                if(this.table_view_mode === 'edit'){
                    this.modal_payload = _.cloneDeep(payload);
                    this.modal_payload['modal_type'] = 'Objective'
                    this.modal_payload['parent_id'] = payload.milestone_objective_id;
                    this.modal_payload['parent_objective_id'] = payload.milestone_objective_id;
                    this.show_objective_modal = true;
                    this.$nextTick(()=>{
                        this.$bvModal.show('linkedObjectiveModal');
                        this.$root.$once("bv::modal::hidden", (event) => {
                            if (event.type == "hidden" && event.componentId == "linkedObjectiveModal") {
                                this.show_objective_modal = false;
                                this.modal_payload = null;
                            }
                        });
                    })
                }
            }
        },
        handleObjectiveChangedEvent(newID){
            //* update the selected table item with the new selected objective id
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.modal_payload.id);
            item['milestone_objective_id'] = Number(newID);
            table.updateItem(this.modal_payload.id, item);
            this.$bvModal.hide('linkedObjectiveModal')//hierarchyModal
            //this.saveTable(true)
        },
    },
    computed: {
        ...levelComputed,
        ...activityComputed,
        ...adminComputed,
        ...cardHeightComputed,
        ...webixTableState,
        ...uxToggleComputed,
        ...performanceComputed,
        mustDisableButton(){
            if(this.table_states.targetsTable != undefined){
                return this.table_states.targetsTable.is_editing_table;
            }
            else{
                return false;
            }
        },
        isCompleteTarget(){
            return this.get_selected_target && this.get_selected_target.target != null
        },
        is_company_level(){
            return this.current_level_id == this.company_id;
        },
        get_selected_target(){
            return this.getSelectedTarget;
        },
        getSelectedLevelName(){
            if(this.selected_level){
                //* return level name and include count of targets
                let name = this.selected_level.name;
                let length = this.key_results.length;
                return `${name} (${length})`
                //return this.selected_level.name;
            }
            return 'No Level Selected';
        },
        hasUnitTypes(){
            if(this.keyResultUnit == null){
                return 0;
            }
            else{
                return this.keyResultUnit.length == 0
            }
        },
        canShowHeaderFilters(){
            if(this.table_states[this.table_uid] == undefined){
                return false;
            }
            else{
                return this.table_states[this.table_uid].show_datatable_filters;
            }
        },
        // show_kpi_component:{
        //     get: function(){
        //         return this.show_kpi_toggle
        //     },
        //     // set: function(newValue){
        //     //     this.setGraphToggleState({
        //     //         type: 'kpi',
        //     //         state: newValue,
        //     //     });
        //     // }
        // },

        areMultipleObjectivesSelected(){
            return this.selected_objectives.length > 0
        },

        availableActions(){
            if(this.show_kpi_component == true){
                return ['view'];
            }
            else{
                return ['edit', 'delete', 'view']
            }
        },
        availableUnitTypes(){
            if(this.keyResultUnit){
                return this.keyResultUnit.map( item => {
                    return {
                        id: item.id,
                        value: item.name
                    }
                })
            }
            else{
                return [];
            }
        },
        getKpiCount(){
            if( !this.getSelectedTarget ){
                return 0;
            }

            let count = 0;
            let found_target = this.level_targets.find( item => {
                return item.id == this.getSelectedTarget.id
            })
            if(found_target){
                count =  found_target.kpis.length
            }
            return count;
            // let count = 0;
            // if(this.key_result && this.key_result.kpis){
            //     count = this.key_result.kpis.length;
            // }
            // return count;
            //return 0;
            // let found_target = this.level_targets.find( item => {
            // return item.id == this.getSelectedTarget.id
            // })
            // if(found_target){
            //     return found_target.kpis.length
            // }
            //return this.level_kpis.length;
        },
        
        getAvailableObjectives(){
            let names = [
                {id: 'null', value: "Not Linked"}
            ];
            
            if(this.activity_data.parent_objectives != undefined){
                this.activity_data.parent_objectives.forEach( item =>{
                    names.push(item);
                })
            }
            this.activity_data.objectives.forEach( item => {
                names.push(item);
            })
            
            return names;
        },
        maxHeight(){
            if(this.row2.kr == this.row2.kpi){
                switch(this.row2.kr){
                    case 'default':{
                        return 'height: 16rem;'
                    }
                    case 'dataset':{
                        return 'height: 17rem;'
                    }
                    case 'expanded':{
                        return 'height: 26rem;'
                    }
                    default:{
                        return ''
                    }
                }
            }
            else{
                //* find biggest size
                switch(this.row2.kr){
                    case 'default':{
                        if(this.row2.kpi == 'dataset'){
                            return 'height: 17rem;'
                        }
                        else{
                            return 'height: 26rem;'
                        }
                    }
                    case 'dataset':{
                        if(this.row2.kpi == 'expanded'){
                            return 'height: 26rem;'
                        }
                        else{
                            return 'height: 17rem;'
                        }
                    }
                    case 'expanded':{
                        return 'height: 26rem;'
                    }
                    default:{
                        return ''
                    }
                }
            }
        },
        hasKeyResults(){
            // check now includes if items have been filtered for organisational/personal
            if(this.key_results.length == 0){
                return false;
            }
            return true;
        },
        graphIndexText(){
            let text = '';
            if(this.level_targets.length){
                text = (this.graph_index + 1) + ' of ' + this.level_targets.length;
            }
            return text;
        },
        isParentLevel(){
            let valid = false;
            if(this.selected_level != null){
                valid = this.selected_level.parent_id == null
            }
            return valid;
        },
        activeUserId(){
            if(this.selected_user_id != null && this.selected_user_id != undefined){
              //* return user id that was selected on the left menu
                return this.selected_user_id;
            }
            else{
                return -1;
                //return this.core.id;// return logged in user
            }
        },
        graph_index(){
            if(this.selected_target == -1){
                return -1;
            }

            let index = _.findIndex(this.level_targets, { id: this.selected_target});
            return index;
        },

        //* table computed props
        variable_watchers(){
            var el = this;
            return {
                //eslint-disable-next-line
                unit_type_id(newVal, oldVal, id, is_new_row){
                    if(!is_new_row && newVal != oldVal){
                        el.$swal.fire('Changing the Unit Type will affect the shareability of this graph');
                    }
                },
                //eslint-disable-next-line
                target(newVal, oldVal, id, is_new_row){
                    let item = window.webix.$$(el.table_uid).getItem(id);
                    if(!is_new_row){
                        item['must_update_target'] = true;
                        item.max_value = newVal;
                        window.webix.$$(el.table_uid).updateItem(id, item)
                    }
                    else{
                        //* for new rows, set max value to target value
                        item.max_value = newVal;
                        window.webix.$$(el.table_uid).updateItem(id, item)
                    }
                },
            }
        },
        simple_columns(){
            let el = this;
            let columns = [
                { id: "name", header: this.canShowHeaderFilters ? ["Targets", {content: "textFilter"}] : "Targets",
                    fillspace: true, tooltip: '',},
                { id: "unit_type_id", header: "Unit Type", editor: "combo", tooltip: '',
                    options: el.availableUnitTypes,
                    minWidth: 150,
                },
                { id: "show_cumulative", editor: "combo", header: "Achieved Entry", minWidth: 100,
                    options: [
                        { id: 'null', value: 'Please select an option'},
                        { id: '0', value: "Discrete" },
                        { id: '1', value: "Cumulative" },
                    ],
                },
                { id: "show_cumulative_target", editor: "combo", header: "Target Type", minWidth: 100,
                    options: [
                        { id: 'null', value: 'Please select an option'},
                        { id: '0', value: "Discrete" },
                        { id: '1', value: "Cumulative" },
                    ],
                },
                { id: "owner_user_id", header: "Owner", tooltip: '',
                    minWidth: 120,
                    options: this.userList,
                    suggest:{
                        view:"suggest",
                        filter:function(item, value){
                            let text = value.toLowerCase();
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if(name.includes(text) || email.includes(text)){
                                return true;
                            }
                            else{
                                return false;
                            }
                        },
                        body:{
                            view:"list",
                            data:this.userList,
                            template:"#value# - #email#",
                            yCount:10
                        }
                    },
                },
            ]
            return columns;
        },
        columns(){
            var el = this;
            return [
                { id: "name", compact: true, header: this.canShowHeaderFilters ? ["Targets", {content: "textFilter"}] : "Targets",
                    editor: "text", sort: "text", width: 400,},
                { id: "target", header: "Target", editor: "text", width: 120},
                { id: "unit_type_id", header: "Unit Type", editor: "combo",
                    options: el.availableUnitTypes, width: 120
                },
                { id: 'current_time_period', header:'Tracking Period', editor: "combo", width: 120,
                    options:()=>{
                        return [
                            {id: 'daily', value: 'Daily'},
                            {id: 'weekly', value: 'Weekly'},
                            {id: 'monthly', value: 'Monthly'},
                            {id: 'yearly', value: 'Yearly'}
                        ]
                    }
                },
                { id: "graph_type", editor: "combo", header: "Type", sort: "text", width: 120,
                    options: [
                        { id: "line", value: "Line" },
                    ],
                },
                { id: "include_weekends", editor: "combo", header: "Include Weekends", sort: "text", width: 140,
                    options: [
                        { id: "1", value: "Yes" },
                        { id: "0", value: "No" },
                    ],
                },
                { id: "show_cumulative", editor: "combo", header: "Achieved Entry", width: 150,
                    options: [
                        { id: '0', value: "Discrete" },
                        { id: '1', value: "Cumulative" },
                    ],
                },
                { id: "show_cumulative_target", editor: "combo", header: "Target Type", width: 150,
                    options: [
                        { id: '0', value: "Discrete" },
                        { id: '1', value: "Cumulative" },
                    ],
                },
                { id: "min_value", editor: "text", header: "Min", sort: "int", width: 120,
                    template: (obj, common, value) => {
                        if (value == 0) return value;
                        if (!value) return "";
                        return value;
                    },
                },
                { id: "max_value", editor: "text", header: "Max", sort: "int", width: 120,
                    template: (obj, common, value) => {
                        if (!value) return "";
                        return value;
                    },
                },
                { id: "owner_user_id", header: "Owner", sort: "text", editor: "combo", width: 200,
                    options: this.userList,
                    suggest:{
                        view:"suggest",
                        filter:function(item, value){
                            let text = value.toLowerCase();
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if(name.includes(text) || email.includes(text)){
                                return true;
                            }
                            else{
                                return false;
                            }
                        },
                        body:{
                            view:"list",
                            data:this.userList,
                            template:"#value# - #email#",
                            yCount:10
                        }
                    },
                },
                {
                    id: "level_id",
                    //editor: "combo",
                    header: 'Hierarchy',
                    //header: this.canShowHeaderFilters ? ["Hierarchy", {content: "textFilter"}] : ["Hierarchy"],
                    sort: "text",
                    width: 250,
                    template:function(obj){
                        if(obj.level_id != undefined){
                            let index = _.findIndex(el.levels, {id: obj.level_id });
                            let level_name = '';
                            if(index != -1){
                                level_name = el.levels[index].name;
                            }
                            if(el.table_view_mode === 'edit'){
                                //* we only need to show the pencil icon in edit mode
                                return `<span class="show_hierarchy_text"><i class="me-1 show_hierarchy_modal fas fa-pen"></i>${level_name}</span>`
                            }
                            else{
                                return `<span>${level_name}</span>`
                            }
                        }
                        else{
                            return ''
                        }
                    },
                },
                {
                    id: 'notes', editor: 'text', header: 'Notes', width: 150,
                }
            ]
        },
        default_values(){
            return [
                { id : "current_time_period", value: 'monthly' },
                { id : "graph_type", value: 'line' },
                { id : "show_cumulative", value: '0'},
                { id : "show_cumulative_target", value: '0'},
                { id : "min_value", value: 0},
                { id : "owner_user_id", value: this.core.id},
                { id : "level_id" , value : this.current_level_id},
                { id : "target_type", value: 'organisational'},
                
                //{ id : "milestone_objective_id", value: this.selected_objective != null ? this.selected_objective.id : -1},
                //{ id : "progress_data_source", value: 'data_input'}
            ]
        },
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'show_hierarchy_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showHierarchyModal(item);
                },
                //eslint-disable-next-line
                'show_objective_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showObjectiveModal(item);
                },
                //eslint-disable-next-line
                'notes_button':function(e, id){
                    let item = this.getItem(id)
                    let note_data = {
                        item: item,
                        can_edit: false,
                        table: 'keyResultPlan'
                    }
                    //el.notes_text = item.notes;
                    el.$eventHub.$emit('showNotesModal', note_data);
                },
            }
            return onClick;
        },
        userList(){
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
        //* --------------------
        titleName() {
            return "Targets";
        },
        objectiveName() {
            if(this.areMultipleObjectivesSelected){
                if(this.selected_key_result != null){
                    let objective = this.activity_data.objectives.find( item => {
                        return item.id == this.selected_key_result.milestone_objective_id;
                    })
                    if(objective){
                        return objective.name;
                    }
                }
                return ''
            }
            else if(this.selected_objective != null){
                return this.selected_objective.name;
                /*return this.selected_objective
                ? this.findObjectiveLevel(this.selected_objective.id)
                : "No Objective Selected";*/
            }
            return '';
        },
        key_results(){
            return this.level_targets;
            //* old filter for personal/organisational
            //return _.filter(this.level_targets, {level_id: this.selected_level.id, target_type: this.filter_mode})
        },
        key_result() {
            //* single key result
            if (this.selected_key_result != null) {
                let kr = _.cloneDeep(this.selected_key_result);
                return kr;
            }
            else {
                return null;
            }
        },
        title() {
            if (this.selected_objective != null) {
                return this.selected_objective.name;
            }
            else {
                return "No Milestone Objective Selected";
            }
        },
        table_validation_rules(){
            return {
                "name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "target": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            const is_greater_than_0 = isNaN(item) == false && Number(item) > 0;
                            const is_less_than_max_value = isNaN(obj.max_value) == false && Number(obj.max_value) >= Number(item);
                            if(is_greater_than_0 && is_less_than_max_value){
                                return true;
                            }
                            else{
                                return false;
                            }
                            
                        }
                    }
                },
                "unit_type_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "graph_type": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "min_value": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "max_value": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "owner_user_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                /*"status": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },*/
            };
        },
        selectedID(){
            if(this.getSelectedTarget != null){
                return this.getSelectedTarget.id;
            }
            else{
                return -1;
            }
        }
    },
    mounted() {
        this.getKeyResultUnitAPI();
    },
};
</script>

<style lang="scss" scoped>
::v-deep .bar {
  width: 100%;
  height: 10px;
  border-radius: 8px;
  background: #e9ecef;
  overflow: hidden;

  &-inner {
    // width: attr(data-percent);
    height: 100%;
    background: dodgerblue;
  }
}



::v-deep .selected-item {
  font-weight: bold;
  text-decoration: underline;
}

::v-deep .bg-red {
  background: rgb(255, 92, 76) !important;
}

::v-deep .border-red {
  border-color: rgb(255, 92, 76) !important;
}
.tile-scroll-container{
    /* background-color: rgba(211, 211, 211, 0.427); */
}
.newTileContainer{
    display: flex;
    overflow-x: auto;
    background: #efefef;
    border: 0;
    /* border-radius: 5px; */
}
.newTile{
    border: 1px solid #d1d1d1;
    flex: 0 0 18rem;
    border-radius: 6px;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.25);
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);  */
}

.tooltipwrapper {
  .tooltiptext {
    visibility: hidden;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 0 5px;

    /* Position the tooltip */
    background-color: #d3d5db;
    color: black;
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0px;
    width: 180px;
    transform: translate(0%, -100%);
    font-size: 12px;

    &:after {
      content: "";
      position: absolute;
      top: calc(100% + -1px);
      left: 50px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #d3d5db transparent transparent transparent;
    }
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
}

.limit_text {
  display: block;
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.upper-text{
    text-transform:capitalize;
}
</style>

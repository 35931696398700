<template>
  <b-overlay :show="is_loading_graph_data">
    <b-card
      no-header
      class="operational bg-light bg-soft border border-success card-hover"
    >
      <template #header>
        <div class="d-flex">
          <strong>
            Operational Targets
          </strong>
          <span class="ms-auto">
            <kbd
              @click="showDatePicker(computedViewableRangeDate.range_start)"
              class="customKbd"
              >{{ getDateRangeString("start") }}</kbd
            >
            &nbsp;
            <span style="font-size:0.6rem;">to</span>
            &nbsp;
            <kbd
              @click="showDatePicker(computedViewableRangeDate.range_end)"
              class="customKbd"
              >{{ getDateRangeString("end") }}</kbd
            >
          </span>
        </div>
      </template>

      <section class="normal-text">
        Show KPIS
        <Targets />
        <div v-if="show_kpi_component">
          <hr class="mt-2 mb-2" v-show="canShowKpis" />
          <transition name="fade">
            <Kpis v-if="canShowKpis" />
          </transition>
        </div>
      </section>
    </b-card>
  </b-overlay>
</template>

<script>
import Vue from "vue";
import { format } from "date-fns";
import {
  activityComputed,
  adminComputed,
  levelComputed,
  performanceComputed,
  performanceMethods
} from "@/state/helpers";
import Targets from "@/components/objectives/components/cards/targets.vue";
import Kpis from "@/components/objectives/components/cards/kpis.vue";
export default {
    components: {
        Targets,
        Kpis,
    },
    data: () => ({
      echoChannelUserId: null,
    }),
    computed: {
        ...activityComputed,
        ...levelComputed,
        ...performanceComputed,
        ...adminComputed,
        computedViewableRangeDate(){
                return this.viewableRangeDate;
            },
        isCompanyLevel(){
            return this.company_id == this.selected_level.id
        },
        canShowKpis() {
            // dont show kpis on top lvl
            if (this.isCompanyLevel) {
                return false;
            }
            else {
                return this.getSelectedTarget != null && this.is_editing_graph_dataset == false;
                //return this.show_kpi_component
                // if(this.getSelectedTarget != null){
                //     return this.getSelectedTarget.id;
                // }
                // else{
                //     return -1;
                // }
            }
        },
    },
    watch: {
        current_level_id: {
            immediate: true,
            handler() {
                this.loadLevelGraphData();
                this.$nextTick(() => {
                    if (this.current_level_id == this.company_id) {
                        this.setShowKpis(false);
                    }
                })
            }
        },
        objective_viewing_quarter() {
            this.loadLevelGraphData();
            this.$nextTick(() => {
                if (this.current_level_id == this.company_id) {
                    this.setShowKpis(false);
                }
            })
        },
        selected_user_id() {
            this.loadLevelGraphData();
            this.$nextTick(() => {
                if (this.current_level_id == this.company_id) {
                    this.setShowKpis(false);
                }
            })
        }
    },
    methods: {
        ...performanceMethods,
        debugGraphData(){

            let params = {
                graph_id: 830,
                graph_type: 'kpi',
                start_date: '2024-01-01',
                end_date: '2024-12-31',
            }
            this.fetchGraphDataset(params);
        },
        connectGraphEvents() {
            console.log("connecting graph events");
            this.echoChannelUserId = this.core.id;
            this.$echoManager.subscribeToPrivateChannel('graph-updates.' + this.echoChannelUserId, 'GraphUpdateEvent', (data) => {
                console.log('EVENT: ', data, new Date().getTime());
                this.setGraphRecalculationState(data);
            });
        },
        getDateRangeString(type) {
            if (this.computedViewableRangeDate.range_start == null) {
                return '';
            }
            return type == 'start'
                ? format(this.computedViewableRangeDate.range_start, 'dd-MMM-yyyy')
                : format(this.computedViewableRangeDate.range_end, 'dd-MMM-yyyy');
        },
        async showDatePicker(initialDate) {
            const { value: date } = await this.$swal.fire({
                title: 'Select date',
                html: '<div id="swal-datepicker"><b-form-input type="date" id="swal-input-date"></b-form-input></div>',
                focusConfirm: false,
                preConfirm: () => {
                    return document.getElementById('swal-input-date').value;
                },
                didOpen: () => {
                    this.bootstrapDatePicker(initialDate);
                    this.focusInput();
                }
            });

      if (date) {
        this.$swal.fire("Selected date: " + date);
      }
    },
    focusInput() {
      const input = document.getElementById("swal-input-date");
      if (input) {
        input.focus();
      }
    },
    bootstrapDatePicker(initialDate) {
      new Vue({
        template: `<b-form-input type="date" value="${this.formatPickerDate(
          initialDate
        )}" />`,
        mounted() {
          document.getElementById("swal-datepicker").appendChild(this.$el);
        }
      }).$mount();
    },
    formatPickerDate(date) {
      const d = new Date(date);
      let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

            return [year, month, day].join('-');
        },
        loadLevelGraphData() {
            return;
            //eslint-disable-next-line
            let graph_params = {
                level_id: this.current_level_id,
                //objective_id: this.selected_goal_objective,
                //objective_data: 1,
                graph_data: 1
            }
            if (this.selected_user_id) {
                graph_params['user_id'] = this.selected_user_id
            }
            this.loadPerformanceScreenData({
                type: 'other',
                params: graph_params,
            })
        }
    },
    mounted(){
        this.connectGraphEvents();
        this.loadLevelGraphData();
    },
    beforeDestroy(){
        this.$echoManager.leaveChannel('graph-updates.' + this.echoChannelUserId)
    }
}
</script>

<style>
.customKbd {
  font-family: unset;
  font-size: 0.6rem;
  background-color: #fff;
  color: #212529 !important;
}
</style>

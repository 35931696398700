<template>
    <div class="">
        <div class="range" v-if="uid != null">
          <input v-model.lazy="slider_value" @input="inputListener" type="range" :min="min_val" :max="max_val" value="0" :id="getSliderID" :disabled="disabled" class="range-input" /> 
          <div style="width: 30%; text-align: center;" class="value2" :class="getSliderID">0</div>
        </div>
    </div>
</template>
<script>
import { debounce } from 'lodash'
export default {
    props:{
      value:{
        default: 0
      },
      min_val: {
        default: 0,
      },  
      max_val:{
        default: 100
      },
      disabled: {
        default: false
      }
    },
    data:() => ({
        uid: null,
        hasMounted: false,
        slider_value: 0,
        display_value: 0,
        sliderEl: null,
        sliderValue: null,
        progress: 0,
    }),
    watch:{
        slider_value: debounce( function (newValue){
            if(this.hasMounted){
              this.$emit('updatedValue', newValue);
            }
        }, 300),

    },
    computed:{
      getSliderID(){
        return `range2-${this.uid}`
      },
    },
    methods:{
      //eslint-disable-next-line
      handleInput(event){
        this.display_value = Number(event.target.value)
      },
        inputListener(event){
            const tempSliderValue = event.target.value; 
            this.updateSlider(tempSliderValue);
            //this.display_value = Number(event.target.value)
        },
        updateSlider(tempSliderValue){
            this.sliderValue.textContent = tempSliderValue;
            this.progress = (tempSliderValue / this.sliderEl.max) * 100;
            this.sliderEl.style.background = `linear-gradient(to right, #50a5f1 ${this.progress}%, #ccc ${this.progress}%)`;
        }
    },
    created(){
      this.uid = window.webix.uid();
    },
    mounted(){
        this.sliderEl = document.querySelector(`#range2-${this.uid}`);
        this.sliderValue = document.querySelector(`.range2-${this.uid}`);
        this.sliderValue.textContent = this.value;
        this.slider_value = this.value;
        this.updateSlider(this.value);
        setTimeout(()=>{
          this.hasMounted = true;
        }, 500)


        //this.sliderEl.addEventListener("input", this.inputListener);
    },
    beforeDestroy(){
        //this.sliderEl.removeEventListener("input", this.inputListener)
    }
  }
  
</script>

<style scoped>

/* range 2 */
.range-input {
-webkit-appearance: none;
appearance: none; 
width: 100%;
cursor: pointer;
outline: none;
border-radius: 15px;
height: 5px;
background: #ccc;
}
.range-input::-webkit-slider-thumb {
-webkit-appearance: none;
appearance: none; 
height: 10px;
width: 10px;
background-color: rgb(0, 21, 255);
border-radius: 50%;
border: none;
transition: .2s ease-in-out;
}
.range-input::-moz-range-thumb {
height: 15px;
width: 15px;
background-color: rgb(0, 81, 255);
border-radius: 50%;
border: none;
transition: .2s ease-in-out;
}
.range-input::-webkit-slider-thumb:hover {
box-shadow: 0 0 0 4px rgba(8, 0, 255, 0.1)
}
.range-input:active::-webkit-slider-thumb {
box-shadow: 0 0 0 4px rgba(8, 0, 255, 0.2)
}
.range-input:focus::-webkit-slider-thumb {
box-shadow: 0 0 0 4px rgba(0, 13, 255, 0.2)
}
.range-input::-moz-range-thumb:hover {
box-shadow: 0 0 0 4px rgba(255,85,0, .1)
}
.range-input:active::-moz-range-thumb {
box-shadow: 0 0 0 4px rgba(255,85,0, .2)
}
.range-input:focus::-moz-range-thumb {
box-shadow: 0 0 0 4px rgba(255,85,0, .2)    
}
.range {
display: flex;
align-items: center;
}
</style>
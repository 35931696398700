<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-03T10:24:59+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-15T20:28:29+02:00
@Copyright: Diversitude 2021
-->



<template>
    <div  :class="is_component_disabled ? 'block-click' : ''">
        <Table
            enable_edit
            allow_select
            prevent_first_row_select
            v-show="canShowGantt == false && show_risks == false"
            :columns="columns"
            enable_pretty_mode
            :card_title_prefix="getCardTitlePrefix"
            :treetable="isTreetableComponent"
            :simple_columns="simple_columns"
            :uid="table_uid"
            :ref="table_uid"
            :data="plans"
            :inner_card_component_name="'objectiveTile'"
            re_order
            has_header_filters
            is_multiselect
            :is_loading="returnLoadingState"
            :context_menu_items="['View', 'Edit', 'Delete']"
            only_editable_columns
            no_empty_rows
            :min_height="100"
            :ignore_edit_only_rules="['level_id', 'linked_objective_id', 'level_milestone_id']"
            :active_id="active_id"
            :validation="table_validation_rules"
            :default_values="default_values"
            :paste_rules="paste_rules"
            :parent_id_variable_name="'parent_objective_id'"
            :child_uid="['activityList', 'keyResultsTable', 'krKpiTable', 'metricsTable']"
            :variable_watchers="variable_watchers"
            @selectedRowEvent="selectedRowEvent"
            @updateViewMode="updateViewMode"
            @saveTable="saveTable"
            enforce_structure_checks
            :edit_permissions="{manager:'objectives-manager', user:'objectives'}"
            :button_methods="button_methods"
            @updatedOrderEvent="onRowIndexUpdate"
            @deleteRows="deleteRow"
            :required_permissions="['objectives', 'objectives-manager']"
            :badge_class="getBadgeClass"
            :default_sort_column="'quarter'"
            :guideline_key="objective_guideline_key"
        >
            <template #title>
                <div class="d-flex">
                    <span>{{ titleName }}</span>
                    &nbsp;&nbsp;>&nbsp;&nbsp;
                    <span :class="hasMilestoneSelected ? 'text-primary' : 'text-danger'" v-show="(objective_type == 'level' || objective_type == 'milestone') && hasMilestoneSelected">{{ mileStoneName }}</span>
                    <span class="text-primary" v-show="(objective_type == 'level' || objective_type == 'milestone') && !hasMilestoneSelected">All Years</span>
                    <span :class="selected_personal_objective != null ? 'text-primary' : 'text-danger'" v-if="objective_type == 'personal'"> {{ personalObjectiveName }}</span>
                    <span :class="hasValidMilestoneGoalSelected ? 'text-primary' : 'text-danger'" v-if="objective_type == 'goal'"> {{ milestoneGoalName }}</span>

                </div>
                
            </template>

            <template #extra-table-actions>
                    <!-- <b-dropdown menu-class="border border-grey p-1" class="me-2" size="sm" variant="light" :text="getQuarterText" ref="quarterDropdown">
                            <div class="w-100 btn-group">
                                <b-button @click="setViewingQuarter(0)" :variant="objective_viewing_quarter == 0 ? 'primary':'outline-dark'" block size="sm">All</b-button>
                                <b-button @click="setViewingQuarter(1)" :variant="objective_viewing_quarter == 1 ? 'primary':'outline-dark'" block size="sm">Q1</b-button>
                                <b-button @click="setViewingQuarter(2)" :variant="objective_viewing_quarter == 2 ? 'primary':'outline-dark'" block size="sm">Q2</b-button>
                                <b-button @click="setViewingQuarter(3)" :variant="objective_viewing_quarter == 3 ? 'primary':'outline-dark'" block size="sm">Q3</b-button>
                                <b-button @click="setViewingQuarter(4)" :variant="objective_viewing_quarter == 4 ? 'primary':'outline-dark'" block size="sm">Q4</b-button>
                            </div>
                    </b-dropdown> -->
                    <b-button title="Show Gantt" v-if="objective_type == 'goal' || objective_type == 'level'" @click="handleShowGantt" size="sm" variant="outline-primary"  class="" style="display: inline-block;">
                        <i class="fas fa-columns"></i>
                    </b-button>
                    <b-button  v-if="objective_type == 'goal' || objective_type == 'level'" @click="showGoalsAsList(!show_objectives_as_list, true)" size="sm" :variant="show_objectives_as_list ? 'info' : 'outline-light'"  class="" style="display: inline-block;">
                        <i class="fas fa-list"  v-b-tooltip.hover.bottomright title="OKR List View"></i>
                    </b-button>
            </template>
            <template #buttons >
                <span class="btn-group me-2" v-show="selected_user_id != null && objective_type == 'goal'">
                    <b-button :disabled="mustDisableButton" size="sm" @click="filter_mode = 'organisational'"
                    v-b-tooltip.hover.bottomright title="Organisational Objectives"
                    :variant="filter_mode == 'organisational' ? 'primary' : 'outline-primary'">
                        <font-awesome-icon icon="fa-users"></font-awesome-icon>
                    </b-button>
                    <b-button :disabled="mustDisableButton" size="sm" @click="filter_mode = 'personal'"
                    v-b-tooltip.hover.bottomright title="Personal Objectives"
                    :variant="filter_mode == 'personal' ? 'primary' : 'outline-primary'">
                    <font-awesome-icon icon="fa-user"></font-awesome-icon>
                    </b-button>
                </span>
            </template>

            <template #extra-items>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="showRisks">
                    Show Risks
                </b-dropdown-item>
            </template>
            <template #extra-view >
                    <ObjectiveListView v-if="show_objectives_as_list"/>
            </template>


        </Table>

        <Risks @showObjectives="showObjectivesTable" v-if="canShowGantt == false && show_risks" />

        <GanttChart v-if="canShowGantt && show_risks == false" />


        <!-- @selectedNewID="handleLevelChangedEvent"  //* old way that updated and saved, isntead of just update-->
        <HierarchyModal
            v-if="show_hierarchy_modal"
            :company_level="isParentLevel"
            :payload="modal_payload"
            @selectedNewID="handleLevelUpdateEvent"
            @updatedNewID="handleLevelUpdateEvent"
        />
        <LinkedObjectiveModal
            v-if="show_objective_modal"
            :payload="modal_payload"
            :objective_type="objective_type"
            @selectedNewID="handleObjectiveUpdateEvent"
            @updateNewID="handleObjectiveUpdateEvent"
        />
        <ObjectiveModal
            v-if="false && show_objective_modal"
            :payload="modal_payload"
            @selectedNewID="handleObjectiveUpdateEvent"
            @updateNewID="handleObjectiveUpdateEvent"
        />
        <b-modal v-if="show_milestone_modal" id="milestoneModal" title="Select Milestone" hide-footer>
            <ul class="listItem" v-for="milestone in milestones_for_modal" :key="milestone.id" >
                <li @click="handleMilestoneChange(milestone.id)" class="listItem" :class="milestone.id == modal_payload.level_milestone_id ? 'text-primary' : ''">{{milestone.name}}</li>
            </ul>
        </b-modal>
    </div>
</template>

<script>

import GanttChart from '@/components/gantt/ganttView';
import Table from '@/components/widgets/table/table.vue'
import ObjectiveListView from '@/components/objectives/performance2/objectiveListView.vue'
import ObjectiveDetails from '@/components/objectives/components/cards/ObjectiveDetails.vue'
import _ from "lodash";
//eslint-disable-next-line
import { parse, parseISO, format, isEqual, isAfter, isBefore, startOfQuarter, endOfQuarter, getYear} from 'date-fns'
//eslint-disable-next-line
import { startOfYear, endOfYear } from 'date-fns'
//eslint-disable-next-line
import { addMonths } from 'date-fns'

import HierarchyModal from '../misc/hierarchyModal.vue'
import LinkedObjectiveModal from '../misc/objectiveModals/linkedObjectiveModal.vue'
import ObjectiveModal from '../misc/objectiveModals/linkedObjectiveModal.vue'
import {
    activityMethods,
    activityComputed,
    peopleComputed,
    peopleMethods,
    levelComputed,
    levelMethods,
    adminComputed,
    adminMethods,
    cardHeightComputed,
    cardHeightMethods,
    webixTableState,
    performanceComputed,
    performanceMethods,
} from "@/state/helpers.js";
import Risks from './objective-risks.vue'
import Swal from "sweetalert2";
export default {
    props: {
        isShowObjectivePlan: {
            type: Boolean,
        },
        is_parent_level:{
            default: false,
        },
        objective_type:{
            default: 'milestone', //* milestone, level or goal
        }
    },
    components: {
        ObjectiveListView,
        //eslint-disable-next-line
        ObjectiveDetails,
        //FilterComponent,
        Risks,
        Table,
        //TableActionButton,
        HierarchyModal,
        ObjectiveModal,
        LinkedObjectiveModal,
        //CardHeader
        GanttChart,
    },
    data: () => ({
        debugggg: null,
        //* linking modal config
        show_hierarchy_modal: false,
        show_objective_modal: false,
        show_milestone_modal: false,
        modal_payload: null,

        //show_kpis: false,
        //* risks
        risk_data:[],
        show_table: true,

        //* table component props
        table_view_mode: 'view',    
        is_loading: false,
        delete_array:[],
        changes_made_flag: false,
        //table_uid: 'objectivesTable',
        
        objective_data: [],

        //* filter component
        filterText: "",
        filter: {
            dateStart: "",
            dateEnd: "",
        },

        flip: true,
        show_risks: false,
        show_description: false,
        show_modal: false,
        smart: {//* removed smart goals stuff
            specific: false,
            measurable: false,
            acheivable: false,
            realistic: false,
            time_based: false,
        },

        periodOptions:[
            'Year',
            'First Half',
            'Last Half',
            'Q1',
            'Q2',
            'Q3',
            'Q4'
        ],

        filter_mode: 'organisational', // or personal,
        show_objectives_as_list: false,
    }),
    computed: {
        ...levelComputed,
        ...activityComputed,
        ...adminComputed,
        ...cardHeightComputed,
        ...webixTableState,
        ...performanceComputed,
        ...peopleComputed,
        objective_guideline_key(){
            return `${this.objective_type}_objectives`
            // if(this.objective_type == 'goal'){
            //     return 'Linked Goal/Objective'
            // }
            // else{
            //     return 'Linked Objective'
            // }
        },
        objective_categories(){
            let list = [
                { id: 'committed', value: 'Committed'},
                { id: 'aspirational', value: 'Aspirational' },
                { id: 'learning', value: 'Learning'}
            ];
            return list;
        },
        mustDisableButton(){
            return false;
        },
        hasValidMilestoneGoalSelected(){
            if(this.selected_user_id != null){
                return true;
            }
            if(this.is_company_level){
                return true;
                //return this.selectedMilestoneGoal != null;
            }
            else{
                return true;
            }
            
        },  
        is_company_level(){
            return this.current_level_id == this.company_id;
        },
        getObjectiveHeaderText(){
            if(this.objective_type == 'goal'){
                return 'Linked Goal/Objective'
            }
            else{
                return 'Linked Objective'
            }
        },
        getBadgeClass(){
            let css = 'badge-item badge-item-objective';
            //badge-item-objective
            switch(this.objective_type){
                case 'level':{
                    
                    break;
                }
                case 'personal':{
                    css = 'badge-item badge-item-objective-personal';
                    break;
                }
                case 'milestone': {
                    css = 'badge-item badge-item-objective-goal';
                    // true for no milestone selected
                    //is_invalid = this.selected_milestone == null;
                    break;
                }
                case 'goal':{
                    
                    //is_invalid = this.selected_milestone_goal == -1;
                    break;
                }
            }
            return css;
        },
        is_component_disabled(){
            // if(window){
            //     return false;
            // }
            // return true if the linked item for an objective is not selected
            let is_invalid = false;

            switch(this.objective_type){
                case 'level':{
                    // true for no milestone selected
                    is_invalid = this.selected_milestone == null
                    break;
                }
                case 'personal':{
                    
                    break;
                }
                case 'milestone': {
                    // true for no milestone selected
                    //is_invalid = this.selected_milestone == null;
                    break;
                }
                case 'goal':{
                    if(this.selected_user_id != null){
                        is_invalid = false;
                    }
                    else{
                        if(this.is_company_level){
                            //is_invalid = this.selected_milestone_goal == -1;
                            is_invalid = false;
                        }
                        else{
                            is_invalid = false;
                        }
                    }
                    break;
                }
            }
            return is_invalid;
        },
        canShowGantt(){
            let is_valid_objective_type = this.objective_type == 'level' || this.objective_type == 'goal';
            if(is_valid_objective_type){
                return this.show_gantt
            }
            else{
                return false;
            }
        },
        isTreetableComponent(){
            if(this.objective_type == 'milestone'){
                return false;
            }
            return true;
        },
        table_uid(){
            return `objectivesTable${this.objective_type.toUpperCase()}`
        },
        variable_watchers(){
            let el = this;
            return {
                //eslint-disable-next-line
                'quarter'(newVal, oldVal, id, is_new_row){
                    if(newVal){
                        let item = window.webix.$$(el.table_uid).getItem(id);
                        let current_year = item.start_date ? item.start_date.split('-')[0] : getYear(new Date());
                        let start_of_period = null;
                        switch(newVal){
                            case 'Q1':{
                                start_of_period = startOfQuarter(new Date(current_year, 0, 1));
                                item['start_date'] = format(start_of_period, 'yyyy-MM-dd');
                                item['deadline'] = format(endOfQuarter(start_of_period), 'yyyy-MM-dd');
                                break; 
                            }
                            case 'Q2':{
                                start_of_period = startOfQuarter(new Date(current_year, 3, 1));
                                item['start_date'] = format(start_of_period, 'yyyy-MM-dd');
                                item['deadline'] = format(endOfQuarter(start_of_period), 'yyyy-MM-dd');
                                break; 
                            }
                            case 'Q3':{
                                start_of_period = startOfQuarter(new Date(current_year, 6, 1));
                                item['start_date'] = format(start_of_period, 'yyyy-MM-dd');
                                item['deadline'] = format(endOfQuarter(start_of_period), 'yyyy-MM-dd');
                                break; 
                            }
                            case 'Q4':{
                                start_of_period = startOfQuarter(new Date(current_year, 9, 1));
                                item['start_date'] = format(start_of_period, 'yyyy-MM-dd');
                                item['deadline'] = format(endOfQuarter(start_of_period), 'yyyy-MM-dd');
                                break; 
                            }
                            case 'Year':{
                                start_of_period = startOfYear(new Date(current_year, 9, 1));
                                item['start_date'] = format(start_of_period, 'yyyy-MM-dd');
                                item['deadline'] = format(endOfYear(start_of_period), 'yyyy-MM-dd');
                                break; 
                            }
                            case 'H1':{
                                start_of_period = startOfQuarter(new Date(current_year, 0, 1));
                                item['start_date'] = format(start_of_period, 'yyyy-MM-dd');
                                item['deadline'] = format(addMonths(start_of_period, 6), 'yyyy-MM-dd');
                                break; 
                            }
                            case 'H2':{
                                start_of_period = startOfQuarter(new Date(current_year, 3, 1));
                                item['start_date'] = format(start_of_period, 'yyyy-MM-dd');
                                item['deadline'] = format(addMonths(start_of_period, 6), 'yyyy-MM-dd');
                                break; 
                            }
                            default:{
                                return;
                            }
                        }
                        window.webix.$$(el.table_uid).updateItem(item.id, item);
                    }
                }
            }
        },
        getCardTitlePrefix(){
            if(this.objective_type == 'goal'){
                return `<b-badge variant="primary" style="font-size: smaller; border-radius: 0.2rem; font-weight: normal; background-color: #38aea2 !important" class="bg-primary bg-soft text-light">&nbsp;GOL </b-badge>&nbsp;`;

            }
            else{
                return `<b-badge variant="primary" style="font-size: smaller; border-radius: 0.2rem; font-weight: normal; background-color: #38aea2 !important" class="bg-primary bg-soft text-light">&nbsp;OB </b-badge>&nbsp;`;
            }
        },
        returnLoadingState(){
            // if(this.is_loading_activity_data){
            //     //main component shows spinner, don't show 2 spinners
            //     return false;
            // }
            return this.isLoadingSpecificObjectiveTypeData || this.is_loading || this.lock_objectives_selection;
        },
        canShowHeaderFilters(){
            if(this.table_states[this.table_uid] == undefined){
                return false;
            }
            else{
                return this.table_states[this.table_uid].show_datatable_filters;
            }
        },
        getAllObjectives(){
            switch(this.objective_type){
                case 'goal':{
                    let objectives = _.cloneDeep(this.goal_objectives);
                    objectives.forEach( item => {
                        item.data = [];
                    });

                    this.milestone_goals.forEach( item => {
                        let index = objectives.findIndex( (objective_item) => {
                            return item.parent_objective_id == objective_item.id;
                        })
                        console.log(index)
                        if(index != -1){
                            objectives[index].data.push(item)
                        }
                        else{
                            objectives.push(item)
                        }
                    });

                    if(this.is_company_level){
                        console.log('OBJECTIVES', objectives)
                        return objectives;
                    }   
                    else{
                        return [...objectives, ...this.parent_objectives_list]
                    }
                    
                }
                case 'personal':{
                    let objectives = _.cloneDeep(this.goal_objectives);
                    objectives.forEach( item => {
                        item.data = [];
                    });

                    this.milestone_goals.forEach( item => {
                        let index = objectives.findIndex( (objective_item) => {
                            return item.parent_objective_id == objective_item.id;
                        })
                        console.log(index)
                        if(index != -1){
                            objectives[index].data.push(item)
                        }
                        else{
                            objectives.push(item)
                        }
                    });

                    if(this.is_company_level){
                        console.log('OBJECTIVES', objectives)
                        return objectives;
                    }   
                    else{
                        return [...objectives, ...this.parent_objectives_list]
                    }
                }
                case 'milestone':{
                    return []
                }
                case 'level':{
                    //let temp = _.cloneDeep(this.goal_objectives_tree);
                    const flatten = (members) => {
                        let data = [];
                        return members.map(m => {
                            if (m.data && m.data.length) {
                                data = [...data, ...m.data];
                            }
                            return m;
                        }).concat(data.length ? flatten(data) : data);
                    };
                    let all_objectives = flatten(this.goal_objectives_tree);
                    all_objectives.push(...this.parent_objectives_list);

                    return all_objectives;
                }
                default:{
                    return [];
                }
            }
        },
        getAvailableObjectives(){
            let names = [
                {id: 'null', value: "Not Linked"}
            ];
            switch(this.objective_type){
                case 'goal':{
                    this.goal_objectives.forEach( item => {
                        names.push({
                            id: item.id.toString(),
                            value: item.name,
                        });
                    })
                    const flatten = (members) => {
                        let data = [];
                        return members.map(m => {
                            if (m.data && m.data.length) {
                                data = [...data, ...m.data];
                            }
                            return m;
                        }).concat(data.length ? flatten(data) : data);
                    };
                    let flat_objectives = flatten(this.milestone_goals);
                    flat_objectives.forEach( (item) => {
                        names.push({
                            id: item.id.toString(),
                            value: item.name,
                        });
                    })
                    break;
                }
                case 'milestone':{
                    break;
                }
            }
            
            // if(this.activity_data.parent_objectives != undefined){
            //     this.activity_data.parent_objectives.forEach( item =>{
            //         names.push(item);
            //     })
            // }
            // this.activity_data.objectives.forEach( item => {
            //     names.push(item);
            // });
            //this.data.forEach( item => {
            //    names.push(item);
            //})
            
            return names;
        },
        activeUserID(){
            if(this.selected_user_id != null){
                //* return user id that was selected on the left menu
                return this.selected_user_id;
            }
            else{
                return -1//this.core.id;// return logged in user
            }
        },
        userList(){
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },

        formattedRiskData(){
            let result = this.risk_data.map((item) => {
                return {
                    date: item.date ? this.getFormattedDate(item.date) : "",
                    id: item.id,
                    parent_objective_id: item.parent_objective_id,
                    impact: item.impact,
                    likelyhood: item.likelyhood,
                    milestone_objective_id: item.milestone_objective_id,
                    name: item.name,
                    user_id: item.user_id,
                    user_name: item.user_name,
                    emptyRow: item.emptyRow,
                    level_milestone_id: item.level_milestone_id,
                };
            });
            return result;
        },
        //* table props 
        table_validation_messages(){
            return {
                name: 'Name Required',
                owner_user_id: 'Owner User Required',
                parent_id: 'Parent objective Req'
            }
        },  
        table_validation_rules(){
            //eslint-disable-next-line
            let el = this;
            return {
                // category(item, obj){
                //     if(obj.is_empty){
                //         return true;
                //     }
                //     else{
                //         if(item == undefined || item == 'null'){
                //             return false;
                //         }
                //         else{
                //             return true;
                //         }
                //     }
                // },
                "name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "level_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                //eslint-disable-next-line
                start_date(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(obj.start_date && obj.deadline){
                            return isBefore(parseISO(obj.start_date), parseISO(obj.deadline)) || isEqual(parseISO(obj.start_date), parseISO(obj.deadline))
                        }
                        else{
                            return true;
                        }
                    }
                },
                //eslint-disable-next-line
                deadline(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(obj.start_date && obj.deadline){
                            return isAfter(parseISO(obj.deadline), parseISO(obj.start_date)) || isEqual(parseISO(obj.start_date), parseISO(obj.deadline))
                        }
                        else{
                            return true;
                        }
                    }
                },
                "owner_user_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },//linked_objective_id
                //eslint-disable-next-line
                "linked_objective_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        switch(el.objective_type){
                            case 'milestone':{
                                return true;
                            }
                            case 'goal':{
                                //* check if goal is linked to milestone objective
                                if(obj.parent_objective_id == -1){
                                    return false;
                                }
                                
                                return true;
                            }
                            default:{
                                return true;
                                // let value = obj.parent_objective_id
                                // if((value === '' || value == undefined || value == null || value == 'null') && !el.isCompanyLevel(obj.level_id)){
                                //     return false;
                                // }
                                // //* need to check is linked level is a parent level
                                // let index = _.findIndex(el.levels, {id: obj.level_id});
                                // if(index != -1){
                                //     if(el.levels[index].parent_objective_id == null){
                                //         //* is parent level
                                //         return true;
                                //     }
                                //     else{
                                //         //* not parent
                                //         if(value === '' || value == undefined || value == null){
                                //             return false;
                                //         }
                                //         else{
                                //             return true;
                                //         }
                                //     }
                                // }
                                // else{
                                //     return false;
                                // }
                            }
                        }
                    }
                }
            };
        },

        //* pass the active selected objective id to the table component (for row autoselect)
        active_id(){
            return this.getActiveId();
        },
        paste_rules(){
            let el = this;
            return [
                { id: 'parent_objective_id', value: 'parent_id'},
                { id: 'level_id', value: 'level_id', default: el.selected_level.id},
                { id : "parent_objective_id", value: 'parent_objective_id', default: null },
            ]
        },
        default_values(){
            let el = this;
            if(el.objective_type == 'milestone'){
                return [
                    { id : "level_id", value: el.selected_level.id },
                    { id : "level_milestone_id", value: el.selected_milestone != null ? el.selected_milestone.id : -1 },
                    { id : "parent_milestone_name", value: el.selected_milestone != null ? `${el.selected_milestone.year} - ${el.selected_milestone.name}` : ''},
                    { id : "parent_objective_id", value: null },
                    { id : "quarter", value: el.selected_milestone != null ? startOfQuarter(new Date(el.selected_milestone.year, 0)) : startOfQuarter(new Date())},
                    { id : 'owner_user_id', value: el.core.id },
                    { id : "start_date", value: el.selected_milestone != null ? format(startOfQuarter(new Date(el.selected_milestone.year, 0)), 'yyyy-MM-dd') : format(startOfQuarter(new Date()), 'yyyy-MM-dd')},
                    { id : "deadline", value: el.selected_milestone != null ? format(endOfQuarter(new Date(el.selected_milestone.year, 0)), 'yyyy-MM-dd') : format(endOfQuarter(new Date()), 'yyyy-MM-dd')},
                    { id : 'category', value: 'committed'}
                ]
            }
            else if(el.objective_type == 'goal'){//
                let data = [
                    { id : "level_id", value: el.selected_level.id },
                    { id : "level_milestone_id", value: null },
                    { id : "parent_objective_id", value: el.selected_milestone_goal },
                    { id : "quarter", value: startOfQuarter(new Date())},
                    { id: 'owner_user_id', value: el.core.id },
                    { id : "start_date", value: format(startOfQuarter(new Date()), 'yyyy-MM-dd')},
                    { id : "deadline", value: format(endOfQuarter(new Date()), 'yyyy-MM-dd')},
                    { id : 'category', value: 'committed'}
                ]
                return data;
            }
            else if(el.objective_type == 'personal'){//
                let data = [
                    { id : "level_id", value: el.selected_level.id },
                    { id : "level_milestone_id", value: null },
                    { id : "parent_objective_id", value: null },
                    { id : "quarter", value: startOfQuarter(new Date())},
                    { id: 'owner_user_id', value: el.employeeDetails.id },
                    { id : "start_date", value: format(startOfQuarter(new Date()), 'yyyy-MM-dd')},
                    { id : "deadline", value: format(endOfQuarter(new Date()), 'yyyy-MM-dd')},
                    { id : 'category', value: 'committed'}
                ]
                return data;
            }
            else{
                return [
                    { id : "level_id", value: el.selected_level.id },
                    { id : "level_milestone_id", value: null },
                    { id : "parent_objective_id", value: null },
                    { id : "quarter", value: startOfQuarter(new Date())},
                    { id: 'owner_user_id', value: el.core.id },
                    { id : "start_date", value: format(startOfQuarter(new Date()), 'yyyy-MM-dd')},
                    { id : "deadline", value: format(endOfQuarter(new Date()), 'yyyy-MM-dd')},
                    { id : 'category', value: 'learning'}
                ]
            }
            
        },
        simple_columns(){
            var el = this;
            let columns = [
                {   
                    id: "progress",
                    tooltip: '#progress#%',
                    header: "Progress",
                    width: '100', compact: true,
                    sort: 'string',
                    format: (value) => {
                        if(value != undefined){
                            return `<div class="bar mt-2"><div class="bar-inner" style="width: ${value}%"></div></div>`
                        }
                        else{
                            return `<div class="bar mt-2"><div class="bar-inner" style="width: 0%"></div></div>${value}`;
                        }
                    },
                },
                {
                    id: "name",
                    header: this.canShowHeaderFilters ? ["Objective", {content: "textFilter"}] : ["Objective"] ,
                    fillspace: true,
                    sort: "string",
                    tooltip: '',
                    exportAsTree: true,
                    css: { "text-align": "left", "word-wrap": "break-word" },
                    template: (obj, common, value) => {
                        if(value != ''){
                            let badge_string = 'badge-item-objective-goal';
                            if(el.objective_type == 'goal') {
                                if(obj.objective_type == 'personal'){
                                    badge_string = 'badge-item-objective-personal';
                                }
                                else{
                                    badge_string = 'badge-item-objective'
                                }
                            }
                            return common.treetable(obj, common) + `<span class='badge-item ${badge_string}'>${obj.name}</span>`;
                        }
                        else{
                            return '';
                        }
                    },
                    // template: (item) => {
                    //     if(el.objective_type == 'goal') {
                    //        if(item.objective_type == 'personal') {
                    //            return `<span class='badge-item badge-item-objective-personal'>${item.name}</span>`
                    //        } else {
                    //           return `<span class='badge-item badge-item-objective'>${item.name}</span>`
                    //        }
                    //     } else {
                    //       return `<span class='badge-item badge-item-objective-goal'>${item.name}</span>`
                    //     }
                    // } 
                },
                {
                    id: "quarter", editor: 'combo', hidden: el.objective_type == 'personal', header: "Quarter", width: 80, options: el.periodOptions, sort: "string",
                    template(obj){
                        if(obj.is_empty || !obj.start_date ){
                            return '';
                        }
                        else{
                            let quarter = format(startOfQuarter(new Date(obj.start_date)), 'Q');
                            return `<div class="d-flex">
                                <div class="ms-auto me-auto">Q${quarter}</div>
                                </div>`;
                        }
                    }
                },
                {
                    id: 'deadline',
                    header: "End Date",
                    minWidth: 120,
                    sort: 'string',
                    tooltip: '',
                    format:function(value){
                        return el.parseDateFormat(value);
                    },
                    css: { "text-align": "left", "word-wrap": "break-word" },
                },
                {
                    id: "owner_user_id",
                    tooltip: '',
                    header: this.canShowHeaderFilters ? ["Owner", { content:"richSelectFilter" }] : ["Owner"],
                    
                    minWidth: 120,
                    options: this.userList,
                    suggest:{
                        view:"suggest",
                        filter:function(item, value){
                            let text = value.toLowerCase();
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if(name.includes(text) || email.includes(text)){
                                return true;
                            }
                            else{
                                return false;
                            }
                        },
                        body:{
                            view:"list",
                            data:this.userList,
                            template:"#value# - #email#",
                            yCount:10
                        }
                    },
                },
                {
                    id: 'notes',
                    hidden: el.objective_type == 'personal',
                    header: "Rationale",
                    width: 80,
                    template:function(obj){
                        if(obj.is_new || obj.is_empty){
                            return '';
                        }
                        if(obj.notes == null || obj.notes == ''){
                            return '';
                        }
                        return `
                        <div style='text-align: center' class="notes_button">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-pen"><path fill="currentColor" d="M256 32C114.6 32 .0272 125.1 .0272 240c0 49.63 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734C1.979 478.2 4.75 480 8 480c66.25 0 115.1-31.76 140.6-51.39C181.2 440.9 217.6 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32z" class=""></path></svg>
                        </div>
                        `
                    }
                }
            ]
            return columns
        },
        columns(){
            var el = this;
            let columns = [
                {   
                    id: "progress",
                    tooltip: '#progress#%',
                    header: "Progress",
                    width: '100', compact: true,
                    format: (value) => {
                        if(value != undefined){
                            return `<div class="bar mt-2"><div class="bar-inner" style="width: ${value}%"></div></div>`
                        }
                        else{
                            return ``;
                        }
                    },
                },
                {
                    id: "name",
                    editor: "text",
                    header: this.canShowHeaderFilters ? ["Objective", {content: "textFilter"}] : ["Objective"] ,
                    width: '400',
                    sort: "text",
                    compact: true,
                    tooltip:(obj)=>{
                        if(obj.is_empty){ return '' }
                        else{
                            return obj.name;
                        }
                    },
                    css: { "text-align": "left", "word-wrap": "break-word" },
                    //template: "{common.treetable()} #name#",
                    template: (obj, common, value) => {
                        if(value != ''){
                            let badge_string = 'badge-item-objective-goal';
                            if(el.objective_type == 'goal') {
                                if(obj.objective_type == 'personal'){
                                    badge_string = 'badge-item-objective-personal';
                                }
                                else{
                                    badge_string = 'badge-item-objective'
                                }
                            }
                            return common.treetable(obj, common) + `<span class='badge-item ${badge_string}'>${obj.name}</span>`;
                        }
                        else{
                            return '';
                        }
                    },
                    exportAsTree: true,
                },

                {
                    id: "category", editor: 'combo', sort:'string', header: "Category", width: 70, options: el.objective_categories,
                    template(obj){
                        if(obj.is_empty){
                            return '';
                        }
                        let category = obj.category
                        if(category == 'learning' || category == 'committed' || category == 'aspirational' ){
                            return `<span style="text-transform: capitalize;">${category}</span>`;
                        }
                        else{
                            return '';
                        }
                    }
                    // format(value){
                    //     // if(value != '')
                    //     // alert(value);
                    //     // return `<span class="text-capitalize">${value}</span>`
                    //     return value;
                    // }
                },

                {
                    id: "level_id",
                    //editor: "combo",
                    header: this.canShowHeaderFilters ? ["Hierarchy", {content: "textFilter"}] : ["Hierarchy"],
                    sort: "text",
                    width: 250,
                    template:function(obj){
                        if(obj.level_id != undefined){
                            let index = _.findIndex(el.levels, {id: obj.level_id });
                            let level_name = '';
                            if(index != -1){
                                level_name = el.levels[index].name;
                            }
                            if(el.table_view_mode === 'edit'){
                                //* we only need to show the pencil icon in edit mode
                                return `<span class="show_hierarchy_text"><i class="me-1 show_hierarchy_modal fas fa-pen"></i>${level_name}</span>`
                            }
                            else{
                                return `<span>${level_name}</span>`
                            }
                        }
                        else{
                            return ''
                        }
                    },
                },
                {
                    id: "level_milestone_id",
                    header: 'Linked Year',
                    width: 220,
                    hidden: el.objective_type == 'level' || el.objective_type == 'goal' || el.objective_type == 'personal',
                    /*editor: 'combo',
                    options: el.getAvailableMilestones(),*/
                    template:function(obj){
                        if(obj.is_new){
                            // if(obj.parent_id != null){
                            //     return '<span class="text-muted">N/A</span>'
                            // }
                            if(obj.parent_milestone_name == undefined){
                                if(obj.level_milestone_id == null){
                                    return `<span class="show_hierarchy_text"><i class="me-1 show_milestone_modal fas fa-pen"></i>Not Linked</span>`
                                }
                                else{
                                    return `<span class="show_hierarchy_text"><i class="me-1 show_milestone_modal fas fa-pen"></i>${obj.parent_objective_id}</span>`
                                }
                            }
                            else{
                                if(obj.parent_objective_id == null){
                                    return `<span class="show_hierarchy_text"><i class="me-1 show_milestone_modal fas fa-pen"></i>${obj.parent_milestone_name}</span>`
                                }
                                else{
                                    return `<span class="show_hierarchy_text"> -- </span>`
                                }
                            }
                        }
                        if(obj.is_empty == undefined){
                            if(obj.parent_id != null){
                                return '<span class="text-muted">N/A</span>'
                            }
                            let name = obj.parent_milestone_name;
                            let year = obj.milestone ? obj.milestone.year : null;
                            let full_name = year != null ?
                                `${year} - ${name}` : name;
                            if(el.table_view_mode === 'edit'){
                                // we only need to show the pencil icon in edit mode
                                return `<span class="show_hierarchy_text"><i class="me-1 show_milestone_modal fas fa-pen"></i>${full_name}</span>`
                            }
                            else{
                                return `<span>${full_name}</span>`
                            }
                        }
                        else{
                            return '';
                        }
                    }
                },
                {
                    id: "linked_objective_id",
                    hidden: el.objective_type == 'milestone',
                    header: el.getObjectiveHeaderText,
                    width: 220,
                    template:function(obj){
                        if(obj.is_empty){
                            return '';
                        }
                        else if(obj.is_new){
                            if(obj.parent_objective_name){
                                return `<span class="show_hierarchy_text">
                                            <i class="me-1 show_objective_modal fas fa-pen"></i>
                                            ${obj.parent_objective_name} 
                                        </span>`
                            }
                            let name = 'Not Linked'; 
                            let found_objective = el.getAllObjectives.find( (item) => {
                                console.log(item.id + ' -- ' + obj.parent_objective_id)
                                return item.id == obj.parent_objective_id
                            })
                            if(found_objective != undefined){
                                // return `<div class="show_objective_modal show_hierarchy_text">
                                //     <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class=" svg-inline--fa fa-pen"><path fill="currentColor" d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" class=""></path></svg>
                                //         <span class="ms-1">Awe</span>
                                // </div>`
                                //return `<div class="show_objective_modal show_hierarchy_text">Awe</div>`
                                name = found_objective.name;
                                return `<span class="show_hierarchy_text">
                                            <i class="me-1 show_objective_modal fas fa-pen"></i>
                                            ${name}
                                        </span>`
                                        
                            }
                            return `<span class="show_hierarchy_text">
                                            <i class="me-1 show_objective_modal fas fa-pen"></i>
                                            ${name}
                                        </span>`
                        }
                        else{
                            if(obj.parent_objective_id){
                                let name = obj.parent_objective_name;

                                if(el.objective_type == 'personal') {
                                    let found_objective = el.getAllObjectives.find( (item) => {
                                    console.log(item.id + ' -- ' + obj.parent_objective_id)
                                    return item.id == obj.parent_objective_id
                                    }) 
                                    name = found_objective.name;
                                }
                                if(el.table_view_mode === 'edit'){
                                    return `
                                        <span class="show_hierarchy_text">
                                            <i class="me-1 show_objective_modal fas fa-pen"></i>
                                            ${name}
                                        </span>`
                                }
                                else{
                                    return `
                                        <span>
                                            ${name}
                                        </span>`
                                }
                            }
                            else if(obj.parent_objective_id === null){
                                if(el.table_view_mode === 'edit'){
                                    return `
                                        <span class="show_hierarchy_text">
                                            <i class="me-1 show_objective_modal fas fa-pen"></i>
                                            Not Linked (Null)
                                        </span>`
                                }
                                else{
                                    return `
                                        <span>
                                            Not Linkable
                                        </span>`
                                }
                            }
                        }
                        
                    }
                },
                {
                    id: 'linked_to',
                    header: 'Linked To',
                    width: 220,
                    template(obj){
                        if(obj.is_new || obj.is_empty){
                            return '';
                        }
                        else{
                            if(obj.parent_objective_id == null){
                                if(obj.level_milestone_id == null){
                                    return `<span class="text-danger">Not Linked</span>`
                                }
                                return `<span>
                                    <b-badge variant="primary" style="border-radius: 0.2rem; font-weight: bold;" class="bg-primary bg-soft text-primary">&nbsp;M </b-badge>&nbsp;
                                    ${obj.parent_milestone_name}
                                </span>`
                                //<b-badge variant="primary" class="bg-primary">{{activity.type}}</b-badge>
                                //return obj.parent_milestone_name;
                            }
                            else{
                                return `<span>
                                    <b-badge variant="info" style="border-radius: 0.2rem; font-weight: bold;" class="bg-primary bg-soft text-dark">&nbsp;O </b-badge>&nbsp;
                                    ${obj.parent_objective_name}
                                </span>`
                                //return obj.parent_objective_name;
                            }
                        }
                    }
                },
                {
                    id: "quarter", editor: 'combo', hidden: (el.objective_type == 'personal' || el.objective_type == 'milestone') , sort:'string', header: "Quarter", width: 70, options: ['Q1','Q2','Q3', 'Q4'],
                    template(obj){
                        if(obj.is_empty || !obj.start_date ){
                            return '';
                        }
                        else{
                            let quarter = format(startOfQuarter(new Date(obj.start_date)), 'Q');
                            return `<div class="d-flex">
                                <div class="ms-auto me-auto">Q${quarter}</div>
                                </div>`;
                        }
                    }
                },
                {
                    id: "start_date",
                    visibility:['edit', 'delete'],
                    editor: "date",
                    header: "Start Date",
                    hidden: el.objective_type == 'milestone',
                    width: 120,
                        format:function(value){
                            return el.parseDateFormat(value);
                        },
                    css: { "text-align": "left", "word-wrap": "break-word" },
                },
                {
                    id: 'deadline',
                    visibility:['edit', 'delete'],
                    editor: "date",
                    header: "End Date",
                    hidden: el.objective_type == 'milestone',
                    width: 120,
                    format:function(value){
                        return el.parseDateFormat(value);
                    },
                css: { "text-align": "left", "word-wrap": "break-word" },
                },
                {
                    id: 'date_range', header: 'Start - End', visibility:['view'], width: 220, css: { "text-align": "left", "word-wrap": "break-word" },
                    template(obj){
                        if(obj.is_new || obj.is_empty){
                            return '';
                        }
                        let start = el.parseDateFormat(obj.start_date);
                        let end = el.parseDateFormat(obj.deadline);

                        if(start === ''){ start = 'Na'}
                        if(end === ''){ end = 'Na'}
                        return `${start} - ${end}`;
                    }
                },
                {
                id: "owner_user_id",
                editor: "combo",
                header: "Owner",
                width: 220,
                options: this.userList,
                suggest:{
                    view:"suggest",
                    filter:function(item, value){
                        let text = value.toLowerCase();
                        let name = item.value.toLowerCase();
                        let email = item.email.toLowerCase();
                        if(name.includes(text) || email.includes(text)){
                            return true;
                        }
                        else{
                            return false;
                        }
                    },
                    body:{
                        view:"list",
                        data:this.userList,
                        template:"#value# - #email#",
                        yCount:10
                    }
                },
                },
                {
                    id: 'notes', header: 'Rationale', hidden: el.objective_type == 'personal', editor: 'text', width: 220
                }
            ];

            //* why does this code make me cry on the inside :(
            //* hide the level_milestone column if the user isn't on the parent level
            if(!el.isParentLevel){
                
                let index = _.findIndex(columns, {id: 'level_milestone_id'});
                columns.splice(index, 1);
                if(el.table_view_mode === 'view'){
                    index = _.findIndex(columns, {id: 'linked_objective_id'});
                    columns.splice(index, 1);
                }
            }
            else if(el.isParentLevel && el.table_view_mode === 'view'){
                let index = _.findIndex(columns, {id: 'level_milestone_id'});
                columns.splice(index, 1);

                index = _.findIndex(columns, {id: 'linked_objective_id'});
                columns.splice(index, 1);
            }


            return columns;
        },
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'show_hierarchy_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showHierarchyModal(item);
                },
                //eslint-disable-next-line
                'show_objective_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showObjectiveModal(item);
                },
                //eslint-disable-next-line
                'show_milestone_modal':function(e, id){
                    let item = this.getItem(id)
                    if(item.parent_id == null){
                        el.showMilestoneModal(item);
                    }
                    else{
                        Swal.fire('Only top level objectives can be linked to a Mission Milestone');
                    }
                },
                //eslint-disable-next-line
                'notes_button':function(e, id){
                    let item = this.getItem(id)
                    let note_data = {
                        item: item,
                        can_edit: false,
                        table: 'missionObjectives'
                    }
                    //el.notes_text = item.notes;
                    el.$eventHub.$emit('showNotesModal', note_data);
                },
            }
            return onClick;
        },
        risk_columns(){
            return [
                { id: 'name', header: 'Title', editor: 'text', fillspace: '2' },
                { id: 'date', header: 'Date', editor: 'date', fillspace: '1.3',
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    },
                },
                { id: 'likelyhood', header: 'Likelyhood (1 Low - 5 High)', editor: 'combo',  fillspace: '1', options:[
                {id: '1', value: '1'},
                {id: '2', value: '2'},
                {id: '3', value: '3'},
                {id: '4', value: '4'},
                {id: '5', value: '5'}
                ]},
                { id: 'impact', header: 'Impact (1 Low - 5 High)', editor: 'combo', fillspace: '1.3', options:[
                {id: '1', value: '1'},
                {id: '2', value: '2'},
                {id: '3', value: '3'},
                {id: '4', value: '4'},
                {id: '5', value: '5'}
                ]},
            ]
        },
        isParentLevel(){
            let valid = false;
            if(this.selected_level != null){
                valid = this.selected_level.parent_id == null
            }
            return valid
        },
        validSmartGoals() {
        if (
            this.smart.specific &&
            this.smart.measurable &&
            this.smart.acheivable &&
            this.smart.realistic &&
            this.smart.time_based
        ) {
            return true;
        } else {
            return false;
        }
        },
        isToppestLevel() {
            let result = _.find(this.levels, { id: this.selected_level.id });
            return !result.parent_id;
        },
        isLoadingSpecificObjectiveTypeData(){
            //is_loading_milestone_objectives
            switch(this.objective_type){
                case 'milestone':{
                    return this.is_loading_milestone_objectives;
                }
                case 'goal':{
                    return this.is_loading_milestone_objectives || false;
                }
                case 'personal':{
                    return false;
                }
                case 'level':{
                    return false;
                }
                default :{
                    return false;
                }
            }
        },
        titleName() {
            let title = '';
            if(this.show_risks == false && this.show_description == false){
                switch(this.objective_type){
                    case 'milestone':{
                        title = 'Goals';
                        break;
                    }
                    case 'personal':{
                        title = 'Personal Objectives';
                        break;
                    }
                    case 'goal':{
                        title = 'Objectives';
                        break;
                    }
                    case 'level':{
                        title = 'Level Objectives';
                        break;
                    }
                }
                
            }
            else if(this.show_risks){
                title = "Objective Risks"
            }
            else if(this.show_description){
                title = "Objective Description"
            }
            return title;
        },
        levelName() {
            return this.selected_level
                ? this.selected_level.name
                : "No Level Selected";
        },
        hasMilestoneSelected(){
            return this.selected_milestone;
        },
        milestoneGoalName(){
            let name = '';
            if(this.selected_user_id != null && this.selected_user_object){
                name = `${this.selected_user_object.name} ${this.selected_user_object.last_name}`
            }
            //if(this.is_company_level){
            if(this.selectedMilestoneGoal){
                name = this.selectedMilestoneGoal.name
            }
            else{
                // old - now shows all goals and only filters once a milestone goal is selected
                name = this.selected_level.name;
                //return 'No Objective Goal Selected'
            }
            return `${name} (${this.plans.length})`
            
        },
        personalObjectiveName(){
            if(this.selected_personal_objective != -1 && this.employeeDetails){
                let objective = this.employeeDetails.personal_objectives.find(item => {
                    return item.id == this.selected_personal_objective
                })
                if(objective.name) {
                    return `${objective.name} (${this.plans.length})`;
                } else {
                    return 'No Personal Objective Selected'
                }
            }
            else{
                return 'No Personal Objective Selected'
            }
        },
        mileStoneName() {
            if(this.hasMilestoneSelected){
                let name = '';
                if(this.selected_milestone.name != null) {
                    name = `${this.selected_milestone.year} - ${this.selected_milestone.name}`;
                } else {
                    name = `${this.selected_milestone.year}`
                }
                return `${name} (${this.plans.length})`  
            }
            else{
                return 'No Milestone Selected';
            }
            //return this.selected_milestone ? this.selected_milestone.name : "All Milestones";
        },
        plans() {
            //* return parsed objectives as tree
            switch(this.objective_type){
                case 'goal':{
                    // let data = [];
                    // let has_milestone_goal = this.selectedMilestoneGoal != null || this.selectedMilestoneGoal != undefined;
                    // let milestone_data = has_milestone_goal ? _.filter(this.milestone_goals, { parent_objective_id : this.selectedMilestoneGoal.id}) : this.milestone_goals;

                    // if(this.filter_mode == 'personal') {
                    //     let personal = _.cloneDeep(this.personal_objectives)
                    //     let personal_objectives = _.uniqBy(personal, 'id');
                    //     data = [...milestone_data, ...personal_objectives]
                    // } else {
                    //     data = milestone_data
                    // }
                    // return data;
                    let data = [];
                    switch(this.filter_mode){
                        case 'organisational':{
                            let has_milestone_goal = this.selectedMilestoneGoal != null || this.selectedMilestoneGoal != undefined;
                            data = has_milestone_goal ? _.filter(this.milestone_goals, { parent_objective_id : this.selectedMilestoneGoal.id}) : this.milestone_goals;
                            break;
                        }
                        case 'personal':{
                            let personal = _.cloneDeep(this.personal_objectives)
                            data = _.uniqBy(personal, 'id');
                            data = data.filter( element => element.level_id == this.current_level_id )
                            break;
                        }
                    }

                    return data;
                }
                case 'personal':{
                    let data = _.cloneDeep(this.employeeDetails.personal_objectives)
                    if(data) {
                        return data
                    } else {
                        return []
                    }
                }
                case 'milestone':{
                    return _.cloneDeep(this.goal_objectives);
                }
                default : {
                    return _.cloneDeep(this.goal_objectives_tree);
                }
            }
        },
        flat_objectives_array(){
            if(this.activity_data && this.activity_data.objectives){
                return this.activity_data.objectives;
            }
            else{
                return [];
            }
        },
        risks() {
        if (this.selected_objective != null && this.selected_objective_details != null) {
            return this.selected_objective_details.risks;
        } else {
            return [];
        }
        },

        milestones_for_modal(){
            let data = [];
            this.company_milestones.forEach( item => {
                data.push({
                    id: item.id,
                    name: item.name,
                })
            });
            return data;
        },
        // getQuarterText(){
        //     if(this.objective_viewing_quarter == 0){
        //         return 'All Quarters'
        //     }
        //     else{
        //         return `Quarter ${this.objective_viewing_quarter}`;
        //     }
        // }
    },
    watch: {
        show_objectives_as_list:{
            immediate: false,
            handler(val){
                this.setIsViewingObjectiveList(val);
            }
        },
        selected_user_id(new_id){
            if(new_id == null){
                //* reset toggle back to organisational
                this.filter_mode = 'organisational'
            }
            // else{
            //     this.filter_mode = 'personal'
            // }
        },
        canShowHeaderFilters(){
            this.$nextTick(()=>{
                //* check if ref is valid (should be)
                if(this.$refs[this.table_uid]){
                    //*call method from table component
                    this.$refs[this.table_uid].refreshVisibleColumns()
                }
            })
        },
        // show_kpis(){
        //     this.$emit('expanded', this.show_kpis);
        //     setTimeout(()=>{
        //         window.webix.$$(this.table_uid).parse(this.plans, 'json');
        //         window.webix.$$(this.table_uid).refresh();
        //     }, 250)
        // },
        risks: {
            immediate: true,
            //deep: true,
            handler() {
                this.risk_data = _.cloneDeep(this.risks);
            },
        },
        plans(){
            // set table back to view mode if data changes
            if(this.$refs[this.table_uid] && this.$refs[this.table_uid].table_mode != 'view'){
                if(this.$refs[this.table_uid].table_mode == 'other'){
                    return;
                }
                this.$refs[this.table_uid].table_mode = 'view';
            }
        },
    },
    
    methods: {
        ...performanceMethods,
        ...activityMethods,
        ...levelMethods,
        ...cardHeightMethods,
        ...peopleMethods,
        handleShowGantt(){
            //this.$plausible.trackEvent('Objective Gantt View');
            this.setShowGantt(true)
        },
        showGoalsAsList(state, force_card_view = false){
            this.show_objectives_as_list = state;
            if(state){
                this.$refs[this.table_uid].showOtherMode()
            }
            else if(force_card_view){
                this.$refs[this.table_uid].showCardView()
            }
        },
        getActiveId(objective_type = this.objective_type){
            switch(objective_type){
                case 'goal':{
                    if(this.selected_personal_objective != -1) {
                        return this.selected_personal_objective
                        ? this.selected_personal_objective
                        : -1;
                    } else {
                        return this.selectedGoalObjective
                        ? this.selectedGoalObjective.id
                        : -1;
                    }  
                }
                case 'milestone':{
                    return this.selectedMilestoneGoal
                    ? this.selectedMilestoneGoal.id
                    : -1;
                }
                case 'personal':{
                    return this.selected_personal_objective
                    ? this.selected_personal_objective
                    : -1;
                }
                default : {
                    return this.selectedLevelObjective
                    ? this.selectedLevelObjective.id
                    : -1;
                }
            }
            
        }, 
        getQueryParams(){
            if(this.objective_type == 'personal') {
                return {
                    level_id: this.current_level_id,
                    user_id: this.selected_user_id ? this.selected_user_id : 0,
                    milestone_data: 1,
                }
            } else {
                return {
                    level_id: this.current_level_id,
                    user_id: this.selected_user_id ? this.selected_user_id : 0,
                    milestone_data: 1,
                    milestone_id: this.selected_milestone.id ? this.selected_milestone.id : null
                }
            } 
        },
        showLoginOverlay: adminMethods.showLoginOverlay,
        isTableMode(type){
            if(this.$refs[this.table_uid] == undefined){
                return false
            }
            else{
                return type === this.$refs[this.table_uid].table_mode;
            }
        },
        isCompanyLevel(query_level_id){
            let level = this.levels.find( lvl => {
                return lvl.parent_id == null && lvl.id == Number(query_level_id);
            })
            //if level is not undefined (found) return true, else return false
            return level != undefined;
        },
        showHierarchyModal(payload){
            if(this.table_view_mode === 'edit'){
                this.modal_payload = _.cloneDeep(payload);
                this.modal_payload['modal_type'] = 'Objective'
                this.show_hierarchy_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('hierarchyModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "hierarchyModal") {
                            this.show_hierarchy_modal = false;
                            this.modal_payload = null;
                        }
                    });
                })
            }
        },
        showMilestoneModal(payload){
            this.modal_payload = payload;
            this.show_milestone_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('milestoneModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "milestoneModal") {
                        this.show_milestone_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
        showObjectiveModal(payload){
            /*if(this.changes_made_flag){
                Swal.fire('Please save new rows before making hierarchy changes')
            }*/
            //else{
                if(this.table_view_mode === 'edit'){
                    this.modal_payload = _.cloneDeep(payload);
                    this.modal_payload['modal_type'] = this.objective_type == 'personal' ? 'personal' : 'objective'
                    this.show_objective_modal = true;
                    this.$nextTick(()=>{
                        this.$bvModal.show('linkedObjectiveModal');
                        this.$root.$once("bv::modal::hidden", (event) => {
                            if (event.type == "hidden" && event.componentId == "linkedObjectiveModal") {
                                this.show_objective_modal = false;
                                this.modal_payload = null;
                            }
                        });
                    })
                }
            //}
        },
        handleMilestoneChange(newID){
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.modal_payload.id);
            item['level_milestone_id'] = Number(newID);
            table.updateItem(this.modal_payload.id, item);
            // if(this.modal_payload.is_new){
                let milestone = this.company_milestones.find( item => item.id == newID);
                if(milestone){
                    item['parent_milestone_name'] = milestone.name;
                }
                this.$bvModal.hide('milestoneModal');
            // }

            // else{
            //     let table_state = table.getState();
            //     this.$bvModal.hide('milestoneModal')//hierarchyModal
            //     this.saveObjectivesTable(true)
            //     .then(()=>{
            //         this.$nextTick(()=>{
            //             table = window.webix.$$(this.table_uid);
            //             table_state.open.forEach( id => {
            //                 if(table.exists(id)){
            //                     table.open(id);
            //                 }
            //             })
            //             if(item.parent_objective_id != null && table.exists(item.parent_objective_id)){
            //                 table.open(item.parent_objective_id);
            //             }
            //             if(table.exists(item.id)){
            //                 table.select(item.id);
            //             }
            //             let index = _.findIndex(this.flat_objectives_array, { id: item.id });
            //             if(index != -1){
            //                 this.$eventHub.$emit('selectedObjective', this.flat_objectives_array[index]);
            //                 this.setSelectedObjective(this.flat_objectives_array[index]);
            //             }
            //         })
            //     })
            // }
        },
        //eslint-disable-next-line
        updateObjectiveNewRowItem(table, row){
            //if(table.exists)
            //item['level_id'] = Number(newID);
            table.updateItem(row.id, row);

        },
        updateNewRowItem(table, row, current_id, new_id){
            table.data.remove(current_id);
            table.add(row, 0, new_id);
            if(table.exists(new_id)){
                table.open(new_id);
            }
            table.select(current_id);
            table.refresh();    
        },
        handleObjectiveUpdateEvent(newID){
            let table = window.webix.$$(this.table_uid);
            console.log('DEBUG', this.modal_payload)
            let item = table.getItem(this.modal_payload.id);
            let parent = _.find(this.milestone_goals, {id:Number(newID)});
            if(!parent){
                parent = _.find(this.parent_objectives_list, {id:Number(newID)});
            }
            if(!parent){
                parent = _.find(this.goal_objectives, {id:Number(newID)});
            }
            if(!parent){
                this.$swal.fire('Error updating linked objective');
                this.$bvModal.hide('linkedObjectiveModal');
            }

            item['parent_objective_name'] = parent.name;
            item['parent_objective_id'] = Number(newID);
            console.log('DEBUG 2', item)
            //* check if parent id exists in table
            //if(table.exists(Number(newID))){
                item['$parent'] = Number(newID);
                item['parent_id'] = Number(newID);
                item['parent'] = Number(newID);
            //}

            //table.updateItem(this.modal_payload.id, item);
            //this.updateNewRowItem(table, item, this.modal_payload.id, newID);
            this.updateObjectiveNewRowItem(table, item);
            this.$bvModal.hide('linkedObjectiveModal');
        },
        handleObjectiveChangedEvent(newID){
            //let table = window.webix.$$(this.table_uid);
            //let item = table.getItem(this.modal_payload.id);
            // if(item.level_milestone_id != null){
            //     Swal.fire({
            //         title: "Updating parent ID",
            //         text: "Warning, this parent objective will become a child objective and will be unlinked from it's linked milestone.",
            //         icon: "warning",
            //         showCancelButton: true,
            //         confirmButtonColor: "#34c38f",
            //         cancelButtonColor: "#f46a6a",
            //         confirmButtonText: "Yes",
            //     }).then((result) => {
            //         if (result.value) {
            //             //* If user selects yes
            //             this.updateObjective(newID, true);
            //         }
            //     });
            // }
            //else{
                this.updateObjective(newID)
            //}
        },
        updateObjective(newID, remove_milestone = false){
                let table = window.webix.$$(this.table_uid);
                let item = table.getItem(this.modal_payload.id);
                item['parent_objective_id'] = Number(newID);
                item['$parent'] = Number(newID);
                if(remove_milestone){
                    item['level_milestone_id'] = null;
                }

                let new_milestone_id = -1;
                let parent_item = table.getItem(Number(newID));
                if(parent_item != undefined){
                    new_milestone_id = parent_item.level_milestone_id
                }

                table.updateItem(this.modal_payload.id, item);
                table.data.eachSubItem(this.modal_payload.id,
                    function(obj){
                        let item = table.getItem(obj.id);
                        item['level_milestone_id'] = new_milestone_id;
                        table.updateItem(obj.id, item);
                    }
                )
                let table_state = table.getState();
                this.$bvModal.hide('linkedObjectiveModal')//hierarchyModal
                this.saveObjectivesTable(true)
                .then(()=>{
                    this.$nextTick(()=>{
                        table = window.webix.$$(this.table_uid);
                        table_state.open.forEach( id => {
                            if(table.exists(id)){
                                table.open(id);
                            }
                        })
                        if(item.parent_objective_id != null && table.exists(item.parent_objective_id)){
                            table.open(item.parent_objective_id);
                        }
                        if(table.exists(item.id)){
                            table.select(item.id);
                        }
                        let index = _.findIndex(this.flat_objectives_array, { id: item.id });
                        if(index != -1){
                            this.$eventHub.$emit('selectedObjective', this.flat_objectives_array[index]);
                            this.setSelectedObjective(this.flat_objectives_array[index]);
                        }
                    })
                })
        },
        //eslint-disable-next-line
        handleLevelChangedEvent(newID){
            let table = window.webix.$$(this.table_uid);
            // eslint-disable-next-line     
            let item = table.getItem(this.modal_payload.id);
            item['level_id'] = Number(newID);
            table.updateItem(this.modal_payload.id, item)
            table.data.eachSubItem(this.modal_payload.id,
                function(obj){
                    let item = table.getItem(obj.id);
                    item['level_id'] = Number(newID);
                    table.updateItem(obj.id, item);
                }
            )

            let table_state = table.getState();
            this.$bvModal.hide('hierarchyModal')
            this.saveObjectivesTable(true)
            .then(()=>{
                //clear edit flag
                this.$refs[this.table_uid].table_mode = 'view';
                this.$nextTick(()=>{
                    table = window.webix.$$(this.table_uid);
                    
                    table_state.open.forEach( id => {
                        if(table.exists(id)){
                            table.open(id);
                        }
                    })
                    if(table.exists(item.id)){
                        table.select(item.id);
                    }

                })
            })
        },
        handleLevelUpdateEvent(newID){
            let table = window.webix.$$(this.table_uid);
            // eslint-disable-next-line     
            let item = table.getItem(this.modal_payload.id);
            item['level_id'] = Number(newID);
            if(this.modal_payload.is_new){
                item['is_new'] = true;
            }
            //this.updateNewRowItem(table, item, this.modal_payload.id, newID)
            table.updateItem(item.id, item);
            this.$bvModal.hide('hierarchyModal')
        },
        getAvailableMilestones(){
            let names = [
                {id: 'null', value: "Not Linked"}
            ];
            if( this.activity_data != null){
                if(this.company_milestones != undefined){
                    this.company_milestones.forEach( item => {
                        names.push({
                            id: item.id,
                            value: item.name,
                        })
                    });
                }
            }
            return names;
        },
        
        updateViewMode(mode){
            this.table_view_mode = mode;
            if(this.objective_type == 'goal'){
                if(mode != 'other'){
                    this.showGoalsAsList(false);
                }
            }
        },
        processTree(data_array){
            var data = _.cloneDeep(data_array);
            //* order all items by their item order
            //data = _.orderBy(data, ['item_order']);
            data.forEach((element) => {
                element["parent"] = element.parent_id;
                element["text"] = element.name;
            });
            data.forEach((ele) => {
                let parent_id = ele.parent_id;
                if (parent_id == null) {
                    //* Parent item -- do nothing
                } 
                else {
                    //* If element is a child element, push to children (data) array.
                    data.forEach((d) => {
                        if (d.id === parent_id) {
                            //* flagged items will be ignored - prevent duplicate items causing the treetable to act as a datatable...
                            ele['flagged'] = true;

                            let childArray = d.data;
                            if (!childArray) {
                                childArray = [];
                            }
                            childArray.push(ele);
                            d.data = childArray;
                        }
                        else{
                            //* hmm
                            //TODO - verify this fixed duplicates not being removed
                            let index = _.findIndex(data, {id: d.parent_id});
                            if(index == -1){
                                d.parent_id = null ;
                            }

                        }
                    });
                }
            });
            if(this.isParentLevel){
                //* Remove duplicate elements
                data = data.filter((ele) => ele.parent_id == null);
            }
            else{
                //* Remove duplicate elements
                data = data.filter((ele) => ele.data != undefined || ele.parent_id == null);
                //* fix for activity-other objectives not showing children as a tree
                data = data.filter( (ele) => ele.flagged == undefined );
            }
            //*Set parent root as expanded;
            /*if(this.data.length > 0){
                data[0]["opened"] = true;
                data[0]["open"] = true;
            }*/
            return data;
        },
        saveAfterDragDrop(id, parent_id){
            let index = _.findIndex(this.flat_objectives_array, {id:id});
            if(index != -1){
                let row = this.flat_objectives_array[index];
                var params = {
                    id: row.id,
                    parent_id: parent_id == 0 ? null : parent_id,
                    level_id: row.level_id,
                    milestone_id: (this.selected_milestone ? this.selected_milestone.id : 0),
                    name: row.name,
                    year: row.year,
                    month: row.month,
                    quarter: row.quarter,
                    status: row.status,
                    draft: 0,
                    start_date: row.start_date,
                    deadline: row.deadline,
                    owner_id: row.owner_user_id,
                }
                let parent_index = _.findIndex(this.flat_objectives_array, {id: parent_id});
                if(parent_index != -1){
                    params.level_id = this.flat_objectives_array[parent_index].level_id;
                }
                this.saveObjectiveAPI(params)
                .then(()=>{
                    if(this.isParentLevel){
                        this.$eventHub.$emit("reloadObjective");
                    }
                    else{
                        this.loadActivityData(this.selected_level.id)
                        .then(() => {})
                    }
                });
            }
        },
        toggleEditMode(){
            this.table_view_mode = 'edit';
            //* Hide the kpis to make the objectives table fullscreen
            //this.$nextTick(()=>{
                //this.show_kpis = false;
            //})
        },
        toggleDeleteMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('delete');
            }
            else{
                this.table_view_mode = 'delete';
            }
        },
        toggleDragMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('drag')
            }
            else{
                this.table_view_mode = 'drag';
            }
        },
        toggleViewMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('view')
            }
            else{
                this.table_view_mode = 'view';
            }

        },
        showDiscardPrompt(type){
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        selectObjectiveMilestone(row){
            if(!row){
                this.selectMilestoneGoal(-1)
            }
            else{
                this.selectMilestoneGoal(row.id)
            }
        },
        selectPersonalObjectiveId(row){
            if(!row){
                this.selectPersonalObjective(-1)
            }
            else{
                this.selectPersonalObjective(row.id)
            }
        },
        selectObjectiveGoal(row){
            if(!row){
                this.selectGoalObjective(-1)
            }
            else{
                if(row.objective_type == 'personal') {
                    this.selectGoalObjective(-1)
                    this.selectPersonalObjective(row.id)
                } else {
                    this.selectPersonalObjective(-1)
                    this.selectGoalObjective(row.id)
                }  
            }
        },
        selectObjectiveLevel(row){
            if(!row){
                this.selectGoalObjective(-1)
            }
            else{
                //alert(`Selecting ${row.id}`);
                this.selectGoalObjective(row.id)
            }
        },
        selectObjectiveLevelOld(row){
            if(!row){
                this.$eventHub.$emit('selectedObjective', null);
                this.setSelectedObjective(null);
                return;
            }
            else{
                if(this.selected_objective && this.selected_objective.id == Number(row.id)){
                    return;
                }
            }
            //if the overlay login modal is shown, don't do anything
            if(this.show_overlay_modal){
                if(this.selected_objective){
                    //* restore the previous selected row in the table
                    window.webix.$$(this.table_uid).select(this.selected_objective.id)
                }
                return;
            }
            //* Event returns the selected row
            if(Array.isArray(row)){
                this.is_loading = true;
                let payload = {
                    rows: row,
                    user_id: this.activeUserID
                }
                this.setSelectedObjectives(payload)
                .then(()=>{
                    this.is_loading = false;
                })
            }
            else{
                this.setSelectedObjective(row);
                let params = {
                    level_id: this.current_level_id,
                    objective_id: this.selected_objective_id,
                    objective_data: 1,
                }
                this.loadPerformanceScreenData({
                    type: 'objective',
                    params: params,
                })
                // let params = {id:row.id, user_id: Number(this.activeUserID)}
                // this.loadObjectiveDetailAPI(params)
            }
        },
        selectedRowEvent(row){
            switch(this.objective_type){
                case 'personal':{
                    this.selectPersonalObjectiveId(row);
                    break;
                }
                case 'goal':{
                    this.selectObjectiveGoal(row);
                    break;
                }
                case 'milestone':{
                    this.selectObjectiveMilestone(row);
                    break;
                }
                default : {
                    this.selectObjectiveLevel(row);
                    break;
                }
            }
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        onRowIndexUpdate(sortData){
            this.is_loading = true;
            this.updateObjectiveRowOrder(sortData)
            .then(()=>{
                this.loadActivityData(this.selected_level.id)
                .then(()=>{
                    this.is_loading = false;
                })
            })
        },
        saveTable(data){
            this.is_loading = true;
            let row_params = [];
            data.forEach( row => {
                console.log(row)
                let milestone_id = row.level_milestone_id ? row.level_milestone_id : 0;
                alert('M-ID ' + milestone_id)
                if(this.selected_milestone != null && milestone_id == 0){
                    milestone_id = this.selected_milestone.id;
                }
                if(row.year === 'N/A'){//Fix bug caused by value being == to 'N/A' .. Must me index id instead
                    row.year = "-1"
                }
                if(row.month === 'N/A'){
                    row.month = "-1"
                }
                let updated_row = {
                    id: row.is_new ? -1 : row.id,
                    milestone_id: milestone_id ? milestone_id : null,//this.selected_milestone == null ? null : this.selected_milestone.id,
                    parent_id: row.parent_objective_id,
                    owner_id: row.owner_user_id,
                    name: row.name,
                    year: row.year != undefined && row.year !== '' ? row.year : '-1',
                    quarter: row.quarter,
                    month: row.month != undefined ? row.month : '-1',
                    status: row.status != undefined ? row.status : 0,
                    // deadline: '',
                    // start_date: '',
                    deadline: row.deadline != undefined ? row.deadline : format(new Date(), 'yyyy-MM-dd'),
                    start_date: row.start_date != undefined ? row.start_date : format(new Date(), 'yyyy-MM-dd'),
                    draft: false,
                    level_id: row.level_id != undefined ? row.level_id : this.selected_level.id,
                    //TODO - Integrate with SMART component
                    smart_validation: {"measurable": "1", "time_based": "1", "realistic": "1", "acheivable": "1", "specific": "1"},
                    notes: !row.notes ? '' : row.notes,
                    type: row.objective_type ? row.objective_type : this.objective_type,
                    category: row.category,
                    scoring_year: '2024',
                    scoring_time_period: 'quarter',
                    scoring_period_key: 4,
                    priority: 'high',
                }
                if(!updated_row.level_id){
                    updated_row.level_id = null;
                }
                if(updated_row.level_id === 'null'){
                    updated_row.level_id = null;
                }

                if(updated_row.level_id == null){
                    updated_row.level_id = this.selected_level.id
                }

                //* do final mutations based on objective type
                if(this.objective_type == 'milestone'){
                    let milestone = this.company_milestones.find( item => {
                        return item.id == row.level_milestone_id
                    });
                    if(milestone){
                        let milestone_year = new Date(milestone.year);
                        updated_row.start_date = format(startOfYear(milestone_year), "yyyy-MM-dd");
                        updated_row.deadline = format(endOfYear(milestone_year), "yyyy-MM-dd");
                    }
                }
                row_params.push(updated_row);
            })
            // if(row_params){
            //     console.log('row_params', row_params);
            //     this.delete_array = [];
            //         this.changes_made_flag = false;
            //         //this.$refs[this.table_uid].table_mode = 'view';
            //         //this.$refs[this.table_uid].showCardView();
            //         this.is_loading = false;
            //         return;
            // }

            this.saveObjectives({
                objectives: row_params,
            })
            .then(()=>{
                Swal.fire({
                    icon:'success',
                    title:'Saved!',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                if(this.objective_type == 'personal') {
                    this.loadEmployeeData(this.selected_user_id)
                }
                let params = this.getQueryParams();
                this.loadPerformanceScreenData({
                    type: 'level',
                    params: params,
                })
                .then(()=>{
                    this.delete_array = [];
                    this.changes_made_flag = false;
                    this.$refs[this.table_uid].table_mode = 'view';
                    this.$refs[this.table_uid].showCardView();
                    this.is_loading = false;
                })
            })
            .catch(()=>{
                this.delete_array = [];
                this.changes_made_flag = false;
                this.$refs[this.table_uid].table_mode = 'view';
                this.$refs[this.table_uid].showCardView();
                this.is_loading = false;
            })
        },
        saveRisksTable(){
            // eslint-disable-next-line
            let table = $$('risks');
            window.webix.$$('risks').editStop();
            this.$nextTick(()=>{
                let is_valid = true;  
                this.plans.forEach( item => {
                    let result = table.validate(item.id);
                    if(result != true){
                        is_valid = false;
                    }
                });
                if(is_valid){
                    this.is_loading = true;
                    const all_data = table.serialize(null, true);
                    let rows_to_save = [];
                    all_data.forEach( row => {
                        if(!row.is_empty){
                            let newRow = row.is_new;
                            var params = {
                                id: newRow ? -1 : row.id,
                                objective_id: this.selected_objective.id,
                                name: row.name,
                                date: typeof row.date === 'string' ? format(parseISO(row.date), 'yyyy-MM-dd') : format(row.date, 'yyyy-MM-dd'),
                                likelyhood: row.likelyhood,
                                impact: row.impact,
                            };
                            rows_to_save.push(params);
                        }
                    })

                    let params = {
                        risks: rows_to_save,
                    }

                    this.saveObjectiveRisk(params)
                    .then(()=>{
                        this.refreshRiskData()
                    });

                }
            })
        },
        saveObjectivesTable(is_reorder_save = false){
            return new Promise((resolve, reject)=>{
                window.webix.$$(this.table_uid).editStop();
                this.$nextTick(()=>{
                    //* allow the table component to show validation errors by calling it's internal method
                    this.$refs[this.table_uid].allowValidationMessage();
                    //eslint-disable-next-line
                    let result = $$(this.table_uid).validate();
                    if(result != true){
                        reject();
                        Swal.fire("Invalid rows found.", "", "warning")
                    }
                    if(result){
                        this.is_loading = true;
                        //eslint-disable-next-line
                        let table = $$(this.table_uid);
                        
                        const all_data = table.serialize(null,true);
                        let rows = [];
                        //*Flatten nested array to save to database. child array -> 'data'
                        const array_search = all_data;
                        while(array_search.length) {
                            //console.log('WHILE LOG 8')
                            let item = array_search.shift();
                            if(item.is_empty == undefined){
                                //* add item to flattened array
                                rows.push(item);
                                if(item.data != undefined){
                                    //* item has children, push children to the end of the search array
                                    array_search.push(...item.data);
                                }
                            }
                        }

                        let row_params = [];
                        rows.forEach( row => {
                            let milestone_id = row.level_milestone_id ? row.level_milestone_id : 0;
                            if(this.selected_milestone != null && milestone_id == 0){
                                milestone_id = this.selected_milestone.id;
                            }
                            if(row.year === 'N/A'){//Fix bug caused by value being == to 'N/A' .. Must me index id instead
                                row.year = "-1"
                            }
                            if(row.quarter === 'N/A'){
                                row.quarter = "-1"
                            }
                            if(row.month === 'N/A'){
                                row.month = "-1"
                            }
                            let updated_row = {
                                id: row.is_new ? -1 : row.id,
                                milestone_id: milestone_id,//this.selected_milestone == null ? null : this.selected_milestone.id,
                                //parent_id: row.parent == undefined ? 0 : row.parent,//row.is_new ? 0 : row.parent,
                                parent_id: row.parent_objective_id,
                                owner_id: row.owner_user_id,
                                name: row.name,
                                year: row.year != undefined && row.year !== '' ? row.year : '-1',
                                quarter: row.quarter != undefined ? row.quarter : '-1',
                                month: row.month != undefined ? row.month : '-1',
                                status: row.status != undefined ? row.status : 0,
                                deadline: row.deadline != undefined ? row.deadline : format(new Date(), 'yyyy-MM-dd'),
                                //deadline: row.deadline != undefined ? row.deadline : format(new Date(), 'dd-MMM-yyyy'),
                                start_date: row.start_date != undefined ? row.start_date : format(new Date(), 'yyyy-MM-dd'),
                                //end_date: row.deadline != undefined ? row.deadline : format(new Date(), 'dd-MMM-yyyy'),
                                //end_date: row.end_date != undefined ? row.end_date : format(new Date(), 'dd-MMM-yyyy'),
                                draft: false,
                                level_id: row.level_id != undefined ? row.level_id : this.selected_level.id,
                                //TODO - Integrate with SMART component
                                smart_validation: {"measurable": "1", "time_based": "1", "realistic": "1", "acheivable": "1", "specific": "1"}
                            }
                            if(!updated_row.level_id){
                                updated_row.level_id = null;
                            }
                            if(updated_row.level_id === 'null'){
                                updated_row.level_id = null;
                            }

                            if(updated_row.level_id == null){
                                updated_row.level_id = this.selected_level.id
                            }
                            row_params.push(updated_row);
                        })

                        let params = {
                            level_id: this.selected_level.id,
                            objectives: row_params,
                        }
                        this.saveObjectiveAPI(params)
                        .then(()=>{
                            this.changes_made_flag = false;
                            Swal.fire({
                                icon:'success',
                                title:'Saved!',
                                toast: true,
                                position: 'top',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            })
                            if(this.isParentLevel){
                                this.loadActivityData(this.selected_level.id)
                                .then(()=>{
                                    resolve();
                                    this.is_loading = false;
                                    this.delete_array = [];
                                    this.changes_made_flag = false;
                                    if(is_reorder_save == false){
                                        this.toggleViewMode();
                                    }
                                    this.is_loading = false;
                                })
                                
                            }
                            else{
                                this.loadActivityData(this.selected_level.id)
                                .then(() => {
                                    resolve();
                                    this.is_loading = false;
                                    this.delete_array = [];
                                    this.changes_made_flag = false;
                                    if(is_reorder_save == false){
                                        this.toggleViewMode();
                                    }
                                    this.is_loading = false;
                                })
                                .catch(()=>{
                                    this.is_loading = false;
                                })
                            }
                        });
                    }
                })
            })
            
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected items?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    if(this.show_risks == false){
                      //* delete path for objectives
                        this.deleteRow();
                    }
                    else{
                        this.deleteRisks();
                    }
                }
            });
        },
        // eslint-disable-next-line
        handleRowDelete(id, msg){
            return new Promise( (resolve) =>  {
                let item = window.webix.$$(this.table_uid).getItem(id);
                const name = item.name;
                //const index = _.findIndex(this.flat_objectives_array, {id: Number(id)})
                //const name = this.flat_objectives_array[index].name;
                Swal.fire({
                    title: name,
                    text: msg,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, delete it!",
                })
                .then( result =>{
                    if(result.value){
                        this.forceDeleteObjectives([id])
                        .then(()=>{
                            if(this.objective_type == 'personal') {
                                this.loadEmployeeData(this.selected_user_id)
                            }     
                            resolve('Said yes');
                        })
                        .catch(()=>{
                            this.is_loading = false;
                        })
                    }
                    else{
                        resolve()
                    }
                })
            })
        },
        deleteRow(data){
            let params = [];
            //let is_deleting_selected_row = !!data.find( row => {
            //    return row.id == this.selected_objective_id;
            //});
            
            data.forEach( item => {
                params.push(item.id);
            })
            this.is_loading = true;
            this.deleteObjectives(params)
            .then( async (response)=>{
                console.log('RESPONSE', response);
                //* here we loop through the responses to find any id's that have linked children
                let msg = response.data.message;
                const keys = Object.keys(msg);
                for(let i = 0; i< keys.length; i++){
                    let message_text = '';
                    let key = keys[i];
                    switch(msg[key]){
                        case 'has_children':{
                            message_text = 'This objective has linked children Objectives';
                            break;
                        }
                        case 'has_activities':{
                            message_text = 'This objective has linked Activity Plans';
                            break;
                        }
                        case 'has_key_results':{
                            message_text = 'This objective has linked Key Results';
                            break;
                        }
                        case 'has_operational_metrics':{
                            message_text = 'This objective has linked Operational Metrics';
                            break;
                        }
                    }
                    if(msg[key] !== 'success'){
                        await this.handleRowDelete(key, message_text);
                    }
                }
                let params = this.getQueryParams();
                this.loadPerformanceScreenData({
                    type: 'level',
                    params: params,
                })
                .then(()=>{
                    this.delete_array = [];
                    if(this.objective_type == 'personal') {
                        this.loadEmployeeData(this.selected_user_id)
                    } 
                    this.changes_made_flag = false;
                    this.$refs[this.table_uid].table_mode = 'view';
                    this.$refs[this.table_uid].showCardView();
                    this.is_loading = false;
                })
                // this.loadActivityData(this.selected_level.id)
                // .then(()=>{
                //     this.is_loading = false;
                //     //this.delete_array = [];
                //     this.changes_made_flag = false;
                //     this.$refs[this.table_uid].table_mode = 'view'; 
                //     this.is_loading = false;
                // })
            })
        },
        deleteRisks(){
            let promises = [];
                this.delete_array.forEach( item => {
                    promises.push(this.deleteObjectiveRisk(item.id));
                })

            this.is_loading = true;
            Promise.all(promises)
            .then(()=>{
                Swal.fire({
                    icon:'success',
                    title:'Deleted selection',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
                this.refreshRiskData();
            });
        },

        showRisks(){
            this.show_risks = !this.show_risks;
            this.show_description = false;
            this.show_table = false;
            this.$nextTick(()=>{
                this.show_table = true;
            })
        },
        showDescription(){
            this.show_description = !this.show_description;
            this.show_risks = false;

        },
        showObjectivesTable(){
            this.show_risks = false;
            this.show_description = false;
            this.show_table = false;
            this.$nextTick(()=>{
                this.show_table = true;
            })
        },
        //setValidation(data) {
        //    if (data.smart_validation != null) {
        //        this.smart.specific = data.smart_validation.specific;
        //        this.smart.measurable = data.smart_validation.measurable;
        //        this.smart.acheivable = data.smart_validation.acheivable;
        //        this.smart.realistic = data.smart_validation.realistic;
        //        this.smart.time_based = data.smart_validation.time_based;
        //    } else {
        //        this.resetValidation();
        //    }
        //},
        resetValidation() {
            //TODO need to add SMART validation again.... 
            Object.keys(this.smart).forEach((key) => {
                this.smart[key] = false;
            });
        },
        searchText(value) {
            if(value === ''){
                window.webix.$$(this.table_uid).filter();
            }
            else{
                let text = value.toString().toLowerCase();
                // eslint-disable-next-line
                $$(this.table_uid).filter(function(obj) {
                    if(obj.name != undefined){
                        return (
                            obj.name
                            .toString()
                            .toLowerCase()
                            .indexOf(text) != -1
                        );
                    }
                });
            }
        },
        searchDate(value) {
            this.filter.dateStart = value.start_date;
            this.filter.dateEnd = value.deadline;
            let start = parseISO(this.filter.dateStart);
            let end = parseISO(this.filter.dateEnd);

            // eslint-disable-next-line
            window.webix.$$(this.table_uid).filter(function(obj) {
                if(obj.name != undefined){
                    let s_d = parseISO(obj.start_date);
                    let e_d = parseISO(obj.deadline);
                    return (
                    isAfter(s_d, start) ||
                    isBefore(e_d, end)
                    );
                }
            });
        },
        resetFilter() {
            this.filterText = "";
            this.filter.dateStart = "";
            this.filter.dateEnd = "";
            window.webix.$$(this.table_uid).filter();
        },
        saveGuideline() {
            this.$refs.guideline.saveGuideline();
        },
        getLocaleDate(date) {
            return new Date(date).toLocaleString([], {
                year: "numeric",
                month: "numeric",
                day: "2-digit",
            });
        },
        getFormattedDate(day) {
            const today = new Date(day);

            return `${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(
                -2
            )}-${("0" + today.getDate()).slice(-2)}`;
        },
        refreshRiskData(){
            //let objective_id = this.selected_objective.id;
                //if(this.isParentLevel){
                    this.is_loading = true;
                    let params = {
                        id: this.selected_objective.id,
                        user_id: this.activeUserID,
                    }
                    this.loadObjectiveDetailAPI(params)
                    .then(()=>{
                        this.changes_made_flag = false;
                        this.delete_array = [];
                        this.toggleViewMode();
                        this.is_loading = false;
                    })
        },

        // setViewingQuarter(quarter){
        //     this.setObjectiveQuarter(quarter)
        //     this.$refs.quarterDropdown.hide();
        //     this.filterObjectivesByQuarter();
        // }
    },
};
</script>

<style lang="scss" scoped>
    .show_hierarchy_text{
        cursor: default;
    }
    .show_hierarchy_text:hover .show_objective_modal{
        color: dodgerblue;
    }
    .show_hierarchy_modal:hover{
        color: dodgerblue;
    }

    .show_hierarchy_text:hover .show_milestone_modal{
        color: dodgerblue;
    }
    .show_milestone_modal:hover{
        color: dodgerblue;
    }

    .selectable_hierarchy_row{
        background-color:rgb(220, 255, 243);
    }
    .webix_tree_item.invalid_selectable_row{
        cursor: not-allowed !important;
        color:gray
        /*background-color:rgb(255, 131, 131);*/
    }



::v-deep .bg-red {
  background: rgb(255, 92, 76) !important;
}

::v-deep .border-red {
  border-color: rgb(255, 92, 76) !important;
}

::v-deep .webix_ss_body {
  cursor: pointer;
}


.tooltipwrapper {
  .tooltiptext {
    visibility: hidden;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 0 5px;

    /* Position the tooltip */
    background-color: #d3d5db;
    color: black;
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0px;
    width: 180px;
    transform: translate(0%, -100%);
    font-size: 12px;

    &:after {
      content: "";
      position: absolute;
      top: calc(100% + -1px);
      left: 50px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #d3d5db transparent transparent transparent;
    }
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
}

::v-deep .bar {
  width: 100%;
  height: 10px;
  border-radius: 8px;
  background: #e9ecef;
  overflow: hidden;

  &-inner {
    // width: attr(data-percent);
    height: 100%;
    background: dodgerblue;
  }
  .listItem:hover{
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>

<template>
    <div class="card card-body">
        <!-- header -->
        <b-overlay :show="is_loading">
            <div class="d-flex mb-1">
                <div class="btn-group">
                    <b-button @click="prevDate()" size="sm" variant="outline-secondary">
                        <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                    </b-button>
                    <span class="text-center px-2 pt-1 w-md">
                        {{ getMonthForIndex(getMonthIndex(item.target_date.split('-')[1])) }}
                        &nbsp;
                        {{ selected_milestone.year }}</span>
                    <b-button @click="nextDate()" size="sm" variant="outline-secondary">
                        <font-awesome-icon icon="arrow-right"></font-awesome-icon>
                    </b-button>
                </div>
                <!-- <b-button size="sm" variant="outline-primary">
                    <strong>
                        Month
                    </strong>
                    : July
                    
                </b-button> -->
                <b-button size="sm" variant="outline-dark" class="ms-auto me-1" disabled="">
                    <strong class="me-2">Data Entry Cadence: </strong>
                    <span style="text-transform: capitalize;">
                        {{cadence}}
                    </span>
                    
                </b-button>
            </div>
            <webix-ui v-if="cadence == 'yearly'" v-model="computed_monthly_data" :config="ux" />    
            <webix-ui v-else v-model="computed_table_data" :config="ui"></webix-ui>
        </b-overlay>
    </div>
</template>

<script>
import { endOfMonth, startOfWeek, endOfWeek, eachDayOfInterval} from 'date-fns'
import { startOfMonth, format, parse, getWeekOfMonth, parseISO, isEqual } from 'date-fns'
import { activityComputed, performanceComputed } from '@/state/helpers'
export default {
    props:{
        item: {
            required: true,
        },
    },
    data:() => ({
        //was_cell_updated: false,
        was_data_updated: false,
        is_loading: false,
        table_data: [],
        cadence: 'weekly',
        total_for_month: 0,

        total_for_year: 0,

        emptyCellText: '<div class="text-center">--</div>',

        check_for_0: false,
    }),
    computed:{
        ...activityComputed,
        ...performanceComputed,
        computed_monthly_data(){
            let el = this;
            return {
                data: [
                    {
                        id: 'input_row',
                        January: el.cadence == 'yearly' ? el.emptyCellText : 0,
                        February: el.cadence == 'yearly' ? el.emptyCellText : 0,
                        March: el.cadence == 'yearly' ? el.emptyCellText : 0,
                        April: el.cadence == 'yearly' ? el.emptyCellText : 0,
                        May: el.cadence == 'yearly' ? el.emptyCellText : 0,
                        June: el.cadence == 'yearly' ? el.emptyCellText : 0,
                        July: el.cadence == 'yearly' ? el.emptyCellText : 0,
                        August: el.cadence == 'yearly' ? el.emptyCellText : 0,
                        September: el.cadence == 'yearly' ? el.emptyCellText : 0,
                        October: el.cadence == 'yearly' ? el.emptyCellText : 0,
                        November: el.cadence == 'yearly' ? el.emptyCellText : 0,
                        December: el.cadence == 'yearly' ? el.emptyCellText : 0,
                        Total: el.emptyCellText,
                    }
                ],
                spans: [
                    [ "quarter_row", "January", 3, 1, '<div class="center_heading_row">Q1</div>', "make_blue"],
                    [ "quarter_row", "April", 3, 1, '<div class="center_heading_row">Q2</div>', "make_blue"],
                    [ "quarter_row", "July", 3, 1, '<div class="center_heading_row">Q3</div>', "make_blue"],
                    [ "quarter_row", "October", 4, 1, '<div class="center_heading_row">Q4</div>', "make_blue"],

                    [ "last_row", "January", 3, 1, el.emptyCellText, ""],
                    [ "last_row", "April", 3, 1, el.emptyCellText, ""],
                    [ "last_row", "July", 3, 1, el.emptyCellText, ""],
                    [ "last_row", "October", 3, 1, el.emptyCellText, ""]
                ]
            }
        },
        computed_table_data(){
            let data = {
                data: this.table_data,
                spans: [
                    [ "last_row", "monday", 7, 1, 'Month', "last_row_total"]
                ]
            }
            return data;
        },
        selectedCadence(){
            return this.cadence.charAt(0).toUpperCase() + this.cadence.slice(1);
        },
        ux(){
            let el = this;
            let config = {
                css: "webix_data_border webix_header_border datasetTable",
                autoheight: true,
                id: 'datasheetTable',
                select: 'cell',
                view: 'datatable',
                spans:true,
                columns: el.getAlternativeColumns,
                editable:true,
                on:{
                    onEditorChange: function(id, value) {
                        // Check if the value is numeric
                        if (!value.match(/^\d*\.?\d*$/)) {
                            // If not numeric, you can revert it to a default value or the last valid value
                            this.undo();
                            window.webix.message("Please enter only numeric values.");
                        }
                    },
                    //eslint-disable-next-line
                    onAfterEditStop(editor, cfg){
                        // if(el.was_cell_updated == false){
                        //     return;
                        // }
                        if (!editor.value.match(/^\d*\.?\d*$/)) {
                            // If not numeric, you can revert it to a default value or the last valid value
                            this.undo();
                            window.webix.message("Please enter only numeric values.");
                        }
                        let is_new_value = editor.value != editor.old;
                        
                        if(is_new_value){
                            switch(el.cadence){
                                case 'yearly':{
                                    el.total_for_year = editor.value;
                                    //el.is_loading = true;
                                    el.saveData({
                                        [el.item.target_date] : editor.value
                                    })
                                    break;
                                }
                            }
                        }
                        //reset flag
                        //el.was_cell_updated = false;
                    }
                },
                ready(){
                    
                    setTimeout(()=>{
                        let table = this;
                        table.add({
                            id: 'quarter_row',
                            'January' : 0,
                            'April' : 0,
                            'July' : 0,
                            'October' : 0,
                        });
                        let obj = el.item.tracking_values[0];
                        table.add({
                            id: 'last_row',
                            'January' : 0,
                            'April' : 0,
                            'July' : 0,
                            'October' : 0,
                            'Total':  Number(obj[Object.keys(obj)[0]]),
                        });
                        el.setEditableFieldsCss();
                        el.selectClosestField();
                        // get last row index and set the row to a span row
                    }, 50)
                    //* Prep to insert a month total span row at the end of the table
                    
                }
            }
            return config;
        },
        ui(){
            let el = this;
            let config = {
                css: "webix_data_border webix_header_border datasetTable",
                autoheight: true,
                id: 'datasheetTable',
                select: 'cell',
                view: 'datatable',
                spans:true,
                columns: el.getCadenceColumns,
                editable:true,
                on:{  
                    // onEditorChange(){
                    //     el.was_cell_updated = true;
                    // },
                    //eslint-disable-next-line
                    onAfterEditStop(editor, cfg){

                        let is_new_value = editor.value != editor.old;
                        if(is_new_value){
                            switch(el.cadence){
                                case 'daily':{
                                    let current_row_index = this.getIndexById(cfg.row);
                                    //* get parent row
                                    let row_above_index = current_row_index - 1;
                                    let parent_row = this.getItem(this.getIdByIndex(row_above_index));
                                    // 
                                    let parsedDate = parse(parent_row[cfg.config.id], 'dd-MM-yyyy', new Date());
                                    let formattedDateString = format(parsedDate, 'dd-MMM-yyyy');
                                    el.is_loading = true;
                                    el.saveData({
                                        [formattedDateString] : editor.value
                                    })
                                    break;
                                }
                                case 'weekly':{
                                    let current_row_index = this.getIndexById(cfg.row);
                                    //* get parent row
                                    let row_above_index = current_row_index - 1;
                                    let parent_row = this.getItem(this.getIdByIndex(row_above_index));
                                    let parsedDate = parse(parent_row.saturday, 'dd-MM-yyyy', new Date());
                                    let formattedDateString = format(parsedDate, 'dd-MMM-yyyy');
                                    el.is_loading = true;
                                    el.saveData({
                                        [formattedDateString] : editor.value
                                    })
                                    break;
                                }
                                case 'monthly':{
                                    el.total_for_month = editor.value;
                                    el.is_loading = true;
                                    el.saveData({
                                        [el.item.target_date] : editor.value
                                    })
                                    break;
                                }
                                case 'quarterly':{
                                    break;
                                }
                            }
                            setTimeout(()=>{
                                el.is_loading = false;
                            }, 1000)
                        }
                        
                    },
                    onBeforeEditStart(cell){

                        // eslint-disable-next-line
                        let row = this.getItem(cell)
                        if(!row){
                            return;
                        }
                        // if(row.display_row){
                        //     this.clearSelection();
                        //     return false;
                        // }
                        if(el.cadence == 'monthly'){
                            return cell.column == 'total' && cell.row == 'last_row';
                            //return row.id == 'last_row'
                        }
                        else if(el.cadence == 'weekly'){
                            if(cell.column == 'total' && cell.row != 'last_row'){

                                return row.display_row == undefined;
                            }
                            this.clearSelection();
                            return false;
                        }
                        else if(el.cadence == 'daily'){
                            if(row.display_row == undefined ){
                                if(el.item.include_weekends){
                                    switch(cell.row){
                                        case 'saturday':{
                                            return false;
                                        }
                                        case 'sunday':{
                                            return false;
                                        }
                                        default:{
                                            return false;
                                        }
                                    }
                                }
                            }
                            return false
                        }
                        else{
                            this.clearSelection();
                            return false;
                        }

                    }
                },
                ready(){
                    let table = this;
                    //setTimeout(()=>{
                        
                        table.add({
                            id: 'last_row',
                            block_edit : el.cadence != 'monthly',
                            is_total_row: true,
                            'monday' : 'Month',
                            'total' : el.total_for_month,
                            'total_row' : true,
                        });
                        table.refresh();
                        table.refreshColumns();

                        el.setEditableFieldsCss();
                        el.selectClosestField();
                        // get last row index and set the row to a span row
                    //}, 100)
                    //* Prep to insert a month total span row at the end of the table
                    
                }

            }
            return config;
        },
        getAlternativeColumns(){
            //let el = this;
            let cols = [];
            cols = [
                { id: 'January', header: { text: 'January',  css:"make_darker_blue",}, fillspace: 1,},
                { id: 'February', header: { text: 'February',  css:"make_darker_blue",}, fillspace: 1},
                { id: 'March', header: { text: 'March',  css:"make_darker_blue",}, fillspace: 1},
                { id: 'April', header: { text: 'April',  css:"make_darker_blue",}, fillspace: 1},
                { id: 'May', header: { text: 'May',  css:"make_darker_blue",}, fillspace: 1},
                { id: 'June', header: { text: 'June',  css:"make_darker_blue",}, fillspace: 1},
                { id: 'July', header: { text: 'July',  css:"make_darker_blue",}, fillspace: 1},
                { id: 'August', header: { text: 'August',  css:"make_darker_blue",}, fillspace: 1},
                { id: 'September', header: { text: 'September',  css:"make_darker_blue",}, fillspace: 1},
                { id: 'October', header: { text: 'October',  css:"make_darker_blue",}, fillspace: 1},
                { id: 'November', header: { text: 'November',  css:"make_darker_blue",}, fillspace: 1},
                { id: 'December', header: { text: 'December',  css:"make_darker_blue",}, fillspace: 1},
                { id: 'Total', header: { text: 'Total',  css:"make_darker_blue",}, fillspace: 1, editor: 'text'},
            ];
            return cols
        },
        getCadenceColumns(){
            //return columns based on the current viewing cadence
            let el = this;
            let cols = [];
            cols = [
                { id: 'monday', header: { text: 'Monday',  css:"make_darker_blue",}, 
                    template:function( obj){
                        if(obj.display_row){
                            return `
                                <div style="text-align: right;">
                                ${obj.monday.split('-')[0]}
                                </div>
                            `;
                        }
                        else{
                            return obj.monday
                        }
                    }
                },
                { id: 'tuesday', header: { text: 'Tuesday',  css:"make_darker_blue",}, 
                    template:function( obj){
                        if(obj.display_row){
                            return `
                                <div style="text-align: right;">
                                ${obj.tuesday.split('-')[0]}
                                </div>
                            `;
                        }
                        else{
                            return obj.tuesday
                        }
                    }
                },
                { id: 'wednesday', header: { text: 'Wednesday',  css:"make_darker_blue",}, 
                    template:function( obj){
                        if(obj.display_row){
                            return `
                                <div style="text-align: right;">
                                ${obj.wednesday.split('-')[0]}
                                </div>
                            `;
                        }
                        else{
                            return obj.wednesday
                        }
                    }
                },
                { id: 'thursday', header: { text: 'Thursday',  css:"make_darker_blue",}, 
                    template:function( obj){
                        if(obj.display_row){
                            return `
                                <div style="text-align: right;">
                                ${obj.thursday.split('-')[0]}
                                </div>
                            `;
                        }
                        else{
                            return obj.thursday
                        }
                    }
                },
                { id: 'friday', header: { text: 'Friday',  css:"make_darker_blue",}, 
                    template:function( obj){
                        if(obj.display_row){
                            return `
                                <div style="text-align: right;">
                                ${obj.friday.split('-')[0]}
                                </div>
                            `;
                        }
                        else{
                            return obj.friday
                        }
                    }
                },
                { id: 'saturday', header: { text: 'Saturday',  css:"make_darker_blue",}, 
                    template:function( obj){
                        if(obj.display_row){
                            return `
                                <div style="text-align: right;">
                                ${obj.saturday.split('-')[0]}
                                </div>
                            `;
                        }
                        else{
                            return obj.saturday
                        }
                    }
                },
                { id: 'sunday', header: { text: 'Sunday',  css:"make_darker_blue",}, 
                    template:function( obj){
                        if(obj.display_row){
                            return `
                                <div style="text-align: right;">
                                ${obj.sunday.split('-')[0]}
                                </div>
                            `;
                        }
                        else{
                            return obj.sunday
                        }
                    }
                },
                {
                    id: 'total', header: {text : 'Week', css:"make_darker_blue" },
                    template:function(obj){
                        if(obj.display_row){
                            let date = parse(obj.saturday, "dd-MM-yyyy", new Date());
                            let week = getWeekOfMonth(date, { weekStartsOn: 1 });
                            return `<div style="text-align: center; font-weight: bold;" >${week}</div>`;
                        }
                        else if(obj.is_total_row){
                            console.log("is_total_row", obj.id, obj)
                            if(el.cadence == 'weekly'){
                                let weekly_sum = 0;
                                let table = window.webix.$$('datasheetTable');
                                table.eachRow(function (row){
                                    let item = table.getItem(row);
                                    if(item.can_sum_data){
                                        weekly_sum += Number(item.total);
                                    }
                                })
                                return `<div style="text-align: center; font-weight: bold;" >${weekly_sum}</div>`;
                                //return weekly_sum
                            }
                            else if(el.cadence == 'daily'){
                                let weekly_sum = 0;
                                let table = window.webix.$$('datasheetTable');
                                table.eachRow(function (row){
                                    let item = table.getItem(row);
                                    if(item.can_sum_data){
                                        weekly_sum += Number(item.monday);
                                        weekly_sum += Number(item.tuesday);
                                        weekly_sum += Number(item.wednesday);
                                        weekly_sum += Number(item.thursday);
                                        weekly_sum += Number(item.friday);
                                        weekly_sum += Number(item.saturday);
                                        weekly_sum += Number(item.sunday);
                                    }
                                })
                                return `<div style="text-align: center; font-weight: bold;" >${weekly_sum}</div>`;

                            }
                            console.log(el.total_for_month)
                            return el.total_for_month;
                        }
                        else{
                            if(el.cadence == 'weekly' || el.cadence == 'monthly' || el.cadence == 'yearly'){
                                //console.log('TOTAL', obj.total)
                                return obj.total
                            }
                            // else if(el.cadence == 'weekly'){
                            //     window.webix.$$('datasheetTable').eachRow(function (row){
                            //         console.log(row);
                            //         return 3
                                    
                            //     })
                            // }
                            let total = 
                                    Number(obj.monday) +
                                    Number(obj.tuesday) +
                                    Number(obj.wednesday) +
                                    Number(obj.thursday) +
                                    Number(obj.friday) +
                                    Number(obj.saturday) +
                                    Number(obj.sunday);
                                return `<div style="text-align: center; font-weight: bold;" >${total}</div>`;}
                    }
                },
            ]
            // add final options to all cols
            cols.forEach( col => {
                col['fillspace'] = 1;
                col['cssFormat'] = this.getCellFormat
            })
            switch(this.cadence){
                
                case 'weekly':{
                    cols.forEach( col => {
                        //* set edit on all columns except total column
                        //if(col.id != 'total'){
                            col.editor = 'text';
                        //}
                    })
                    break;
                }
                case 'monthly':{
                    cols.forEach( col => {
                        //* allow total column to be edited
                        //if(col.id == 'total'){
                            col.editor = 'text';
                        //}
                    })
                    break;
                }
                case 'daily':{
                    cols.forEach( col => {
                        //* allow total column to be edited
                        if(col.id != 'total'){
                            col.editor = 'text';
                        }
                    })
                    break;
                }
            }
            return cols;
        },
    },
    watch:{
        item:{
            deep:true,
            handler(){
                this.initTable();
                this.processTrackingData();
                this.$nextTick(()=>{
                    if(this.cadence != 'yearly'){
                        let table = window.webix.$$('datasheetTable');
                        table.add({
                            id: 'last_row',
                            block_edit : this.cadence != 'monthly',
                            is_total_row: true,
                            'monday' : 'Month',
                            'total' : this.total_for_month,
                            'total_row' : true,
                        });
                        table.refresh();
                        table.refreshColumns();
                    }
                    this.setEditableFieldsCss();
                    this.selectClosestField();
                })
            }
        }
    },
    methods:{
        nextDate(){
            let table = window.webix.$$('datasheetTable');
            table.editStop();
            this.$emit('nextDate')
        },
        prevDate(){
            let table = window.webix.$$('datasheetTable');
            table.editStop();
            this.$emit('prevDate');
        },
        setEditableFieldsCss(){
            let table = window.webix.$$('datasheetTable');
            switch(this.cadence){
                case 'yearly':{
                    table.addCellCss('last_row', "Total", "display_as_editable", false);
                    break;
                }
                case 'monthly':{
                    table.addCellCss('last_row', "total", "display_as_editable", false);
                    break;
                }
                case 'weekly':{
                    table.eachRow(function (row){
                        let item = table.getItem(row);
                        if(item.display_row || item.id == 'last_row'){
                            return;
                        }
                        else{
                            table.addCellCss(row, "total", "display_as_editable", false)
                        }
                        
                    })
                    break;
                }
                case 'daily':{
                    let el = this;
                    table.eachRow(function (row){
                        let item = table.getItem(row);
                        if(item.display_row || item.id == 'last_row'){
                            return;
                        }
                        else{
                            table.addCellCss(row, "monday", "display_as_editable", false)
                            table.addCellCss(row, "tuesday", "display_as_editable", false)
                            table.addCellCss(row, "wednesday", "display_as_editable", false)
                            table.addCellCss(row, "thursday", "display_as_editable", false)
                            table.addCellCss(row, "friday", "display_as_editable", false)
                            if(el.item.include_weekends){
                                table.addCellCss(row, "saturday", "display_as_editable", false)
                                table.addCellCss(row, "sunday", "display_as_editable", false)
                            }
                            // else{
                            //     table.addCellCss(row, "saturday", "display_as_editable", false)
                            //     table.addCellCss(row, "sunday", "display_as_editable", false)
                            // }
                            
                        }
                        
                    })
                    break;
                }
            }
        },
        findWeekdayKey(dateString, dateObject) {
            // Parse the input date string to a Date object
            const inputDate = parse(dateString, 'dd-MMM-yyyy', new Date());

            // Search through the dateObject to find a matching date
            for (const [key, value] of Object.entries(dateObject)) {
                const objectDate = new Date(value);
                // Format both dates to 'yyyy-MM-dd' to ensure they can be directly compared
                if (format(inputDate, 'yyyy-MM-dd') === format(objectDate, 'yyyy-MM-dd')) {
                return key; // Return the key if a match is found
                }
            }

            return null; // Return null if no match is found
        },
        selectClosestField(){
            let table = window.webix.$$('datasheetTable');
            switch(this.cadence){
                case 'daily':{
                    let el = this;
                    table.eachRow(function (row){
                        let item = table.getItem(row);
                        if(item.meta != undefined){
                            let key = el.findWeekdayKey(el.item.target_date, item.meta);
                            if(key != null){
                                table.select(item.id, key);
                                table.edit({
                                    row: item.id, column: key
                                })
                                //table.addCellCss(item.id, key, "last_row_total", false);
                            }
                        }
                    })
                    break;
                }
                case 'weekly':{
                    let el = this;
                    table.eachRow(function (row){
                        let item = table.getItem(row);
                        if(item.meta != undefined){
                            let key = el.findWeekdayKey(el.item.target_date, item.meta);
                            if(key != null){
                                //alert(key)
                                table.select(row, 'total');
                                table.edit({
                                    row: row, column: 'total'
                                })
                                //table.addCellCss(item.id, key, "last_row_total", false);
                            }
                        }
                        //if(item.display_row || item.id == 'last_row'){
                        //    return;
                        //}
                        //else{
                        //table.addCellCss(row, "total", "display_as_editable", false)
                        //}
                        
                    })
                    break;
                }
                case 'monthly':{
                    this.is_loading = true;
                    setTimeout(()=>{
                        console.log('EDIT', {
                            row: 'last_row', column: 'total'
                        })
                        table.edit({
                            row: 'last_row', column: 'total'
                        })
                        this.is_loading = false;
                    }, 500)
                    break;
                }
                case 'yearly':{
                    table.edit({
                        row: 'last_row', column: 'Total'
                    })
                    break;
                }
            }
        },
        saveData(updated_value){
            this.is_loading = false;
            this.was_data_updated = true;
            //this.$swal.fire(updated_value);
            
            let date = Object.keys(updated_value)[0];
            let value = updated_value[date]; 
            let swal_string = `Saved ${date} as ${value}`
            this.$swal.fire({
                icon:'success',
                title: swal_string,
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 6000,
                timerProgressBar: true,
            })
            this.$emit('handleSoftSave', updated_value);
            // switch(this.cadence){
            //     case 'weekly':{
            //         this.is_loading = false;
            //         this.was_data_updated = true;
            //         this.$emit('handleSoftSave', updated_value);
            //         return;
            //     }
            //     case 'daily':{
            //         this.is_loading = false;
            //         this.was_data_updated = true;
            //         this.$emit('handleSoftSave', updated_value);
            //         return;
            //     }
            //     case 'daily':{
            //         this.is_loading = false;
            //         this.was_data_updated = true;
            //         this.$emit('handleSoftSave', updated_value);
            //         return;
            //     }
            // }
            // this.$emit('handleSave', updated_value)
        },
        //eslint-disable-next-line
        getCellFormat(value, config, row_id, col_id){
            if(config.display_row == true) {
                return col_id == 'total' ? 'make_darker_blue' : 'make_blue';
            }
            else {
                return ''
            }
        },
        getDayFromDate(date){
            // Get the timezone offset in minutes, then convert it to milliseconds
            const timezoneOffset = date.getTimezoneOffset() * 60000;
            // Adjust the date by the timezone offset to get a "local" UTC date
            const adjustedDate = new Date(date.getTime() - timezoneOffset);
            // Now format the adjusted date
            const formattedDate = format(adjustedDate, 'dd');
            return formattedDate;
        },
        getMonthForIndex(index){
            let list = [
                'January', 'February', 'March',
                'April','May', 'June',
                'July', 'August', 'September',
                'October', 'November', 'December'
            ];
            return list[index];
        },
        getMonthIndex(month){
            let index = [
                'Jan', 'Feb', 'Mar',
                'Apr','May', 'Jun',
                'Jul', 'Aug', 'Sep',
                'Oct', 'Nov', 'Dec'
            ].findIndex( item => { return item == month});
            return index;
        },
        getWeeksForMonth(date, options = { weekStartsOn: 0 }) {
            const monthStart = startOfMonth(date);
            const monthEnd = endOfMonth(date);

            const firstWeekStart = startOfWeek(monthStart, options);
            const lastWeekEnd = endOfWeek(monthEnd, options);

            const days = eachDayOfInterval({ start: firstWeekStart, end: lastWeekEnd });

            const weeks = [];
            let week = [];

            days.forEach(day => {
                if (week.length > 0 && day.getDay() === options.weekStartsOn) {
                weeks.push(week);
                week = [];
                }
                week.push(day);
            });

            if (week.length) {
                weeks.push(week);
            }

            return weeks;
        },
        processTrackingData(){
            let date = {
                year: this.item.target_date.split('-')[2],
                month: this.getMonthIndex(this.item.target_date.split('-')[1])
            }
            let weeks = this.getWeeksForMonth(new Date(date.year, date.month), { weekStartsOn: 1 })
            let data_array = [];
            weeks.forEach( (week, idx) => {
                let display_row = {
                    'monday' :      format(week[0], 'dd-MM-yyyy'),
                    'tuesday' :     format(week[1], 'dd-MM-yyyy'),
                    'wednesday' :   format(week[2], 'dd-MM-yyyy'),
                    'thursday' :    format(week[3], 'dd-MM-yyyy'),
                    'friday' :      format(week[4], 'dd-MM-yyyy'),
                    'saturday' :    format(week[5], 'dd-MM-yyyy'),
                    'sunday' :      format(week[6], 'dd-MM-yyyy'),
                    'total' :       idx,
                    'display_row' : true,
                }
                let input_row = {
                    'monday' : '',
                    'tuesday' : '',
                    'wednesday' : '',
                    'thursday' : '',
                    'friday' : '',
                    'saturday' : '',
                    'sunday' : '',
                    'total' : '',
                    'can_sum_data' : true,
                }
                switch(this.cadence){
                    case 'daily':{
                        input_row.monday = this.findValueByDate(week[0])
                        input_row.tuesday = this.findValueByDate(week[1])
                        input_row.wednesday = this.findValueByDate(week[2])
                        input_row.thursday = this.findValueByDate(week[3])
                        input_row.friday = this.findValueByDate(week[4])
                        input_row.saturday = this.findValueByDate(week[5])
                        input_row.sunday = this.findValueByDate(week[6])
                        input_row.meta = {
                            'monday' : week[0],
                            'tuesday' : week[1],
                            'wednesday' : week[2],
                            'thursday' : week[3],
                            'friday' : week[4],
                            'saturday' : week[5],
                            'sunday' : week[6],
                        }
                        break;
                    }
                    case 'weekly':{
                        //* set to end of weeks value
                        input_row.total = this.findValueByDate(week[5])
                        
                        input_row.monday = this.emptyCellText;
                        input_row.tuesday = this.emptyCellText
                        input_row.wednesday = this.emptyCellText
                        input_row.thursday = this.emptyCellText
                        input_row.friday = this.emptyCellText
                        input_row.saturday = this.emptyCellText
                        input_row.sunday = this.emptyCellText;
                        input_row.meta = {
                            'monday' : week[0],
                            'tuesday' : week[1],
                            'wednesday' : week[2],
                            'thursday' : week[3],
                            'friday' : week[4],
                            'saturday' : week[5],
                            'sunday' : week[6],
                        }
                        break;
                    }
                    case 'monthly':{
                        input_row.total = this.emptyCellText;
                        input_row.monday = this.emptyCellText;
                        input_row.tuesday = this.emptyCellText
                        input_row.wednesday = this.emptyCellText
                        input_row.thursday = this.emptyCellText
                        input_row.friday = this.emptyCellText
                        input_row.saturday = this.emptyCellText
                        input_row.sunday = this.emptyCellText
                        break;
                    }
                }
                data_array.push(display_row);
                data_array.push(input_row);

            })
            
            //* add last span row for monthly input

            this.table_data = data_array;
        },
        getValueForDate(date_object){
            console.log(this.getSelectedTarget.tracking);
            console.log(date_object);
        },
        findValueByDate(searchDate) {
            if(this.item.item_type == 'kpi'){
                const val = this.searchKpiArray(searchDate);
                return !val ? 0 : val;
            }
            else{
                return this.searchTargetArray(searchDate);
            }
            
            //return entry != undefined ? entry.value : 0
        },
        searchKpiArray(searchDate){
            const dateFormat = 'dd-MMM-yyyy';
            // find kpi for the given date
            const entry = this.item.tracking_values.find( entry => {
                const entryDate = parse(
                    Object.keys(entry)[0],
                    dateFormat,
                    new Date()
                );
                return isEqual(entryDate, searchDate);
            })
            if(entry != undefined){
                let value = Object.values(entry)[0];
                this.total_for_month += value;
                return value;
            }
            else{
                return 0;
            }
        },
        searchTargetArray(searchDate){
            //* Find target for the given date
            const entry = this.getSelectedTarget.tracking.find((entry) => {
                const entryDate = parseISO(entry.achieved_date);
                return isEqual(entryDate, searchDate);
            });
            if(entry != undefined){
                let value = this.item.item_name == 'Target'
                ?   entry.target_value != null ? entry.target_value : 909
                :   entry.value;

                this.total_for_month += value;
                return value;
            }
            else {
                return '';
            }

        },
        initTable(){
            this.cadence = this.item.tracking_type;
            switch(this.cadence){
                case 'monthly':{
                    //* check if there is a tracking valuem and if monthly there will only be one
                    if(this.item.tracking_values.length > 0){
                        let obj = this.item.tracking_values[0];
                        let is_empty_value = obj[Object.keys(obj)[0]] == "";
                        if(is_empty_value){
                            this.total_for_month = "";
                        }
                        else{
                            this.total_for_month = Number(obj[Object.keys(obj)[0]]);
                        }
                    }
                    break;
                }
                case 'yearly':{
                    if(this.item.tracking_values.length > 0){
                        let obj = this.item.tracking_values[0];
                        this.total_for_year = Number(obj[Object.keys(obj)[0]]);
                    }
                    break;
                }
            }
        }
    },
    created(){
        // set variables on init
        this.initTable()
    },
    mounted(){
        this.processTrackingData();
    },
    beforeDestroy(){
        if(this.was_data_updated){
            this.$emit('reloadGraphData');
        }
    }
    
}
</script>

<style>
.last_row_total{
    text-align: right;
    font-weight: bold;
}
.make_background_dark{
    background: rebeccapurple;
}
    .make_blue{
        background-color:#bed9ff;
    }
    .make_blue > div{
        font-size: 0.69rem;
        color:#747474;
        margin-top: -4px;
        margin-right: -7px;
    }
    .make_darker_blue{
        background-color:#8bb9f9;
    }
    .make_darkest_blue{
        background-color: #65a3f9;
    }

    .display_as_editable{
        background-color: #e6ffe8 !important;
        text-align: center;
    }
    .center_heading_row{
        text-align: center;
        font-weight: bold;
    }
</style>